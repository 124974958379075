import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { theme } from '../theme';
import ComplianceIcon from './compliance.png';
import CoursesIcon from './courses.png';
import DashboardIcon from './dashboard.png';
import FindNewIcon from './findnew.png';
import LogoutIcon from './logout.png';
import ProfileIcon from './profile.png';
import HelpIcon from './help.png';

/* eslint-disable-next-line */
export interface NavigationProps {}

const StyledNavigation = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  margin: 0 0.8rem;
  padding: 0.8rem 0 0;

  .help, .logout {
    display: none;
  }

  a {
    color: ${theme.font};
    text-decoration: none;

    &.active {
      color: ${theme.orange};
    }
  }

  img {
    height: 3.2rem;
    margin-right: 0.8rem;
    vertical-align: middle;
    width: 3.2rem;
  }

  li {
    margin-bottom: 0.8rem;

    &.compliance, &.mycpd {
      span:before {
        content: "My";
      }
    }

    &.dashboard {
      span:before {
        content: "";
      }
    }
  }

  @media only screen and ${theme.largeDevice} {
    .help {
      display: block;
    }
  }

  @media only screen and ${theme.extraLargeDevice} {
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    margin: 0;
    padding: 0;

    .logout {
      bottom: 0;
      display: block;
      position: fixed;
    }
  }

  @media only screen and ${theme.notLargeDevice} {
    font-size: 1.3rem;
    justify-content: center;
    margin: 0;

    img {
      display: block;
      margin: 0 auto;
    }

    li {
      margin: 0 0.8rem;
      text-align: center;
    }

    .logo, .purchase {
      display: none;
    }
  }
`;

export const Navigation = (props: NavigationProps) => {
  const { help, login: { logout }, magento: { legalcpd } } = useEnvironment();

  return (
    <StyledNavigation>
      <li className="dashboard"><NavLink end to="/"><img src={DashboardIcon} /><span>Dashboard</span></NavLink></li>
      <li className="mycpd"><NavLink to="/cpd"><img src={CoursesIcon} /><span>Courses</span></NavLink></li>
      <li className="compliance"><NavLink to="/compliance"><img src={ComplianceIcon} /><span>Compliance</span></NavLink></li>
      <li className="purchase"><a href={legalcpd}><img src={FindNewIcon} />Find New Courses</a></li>
      <li><NavLink to="/profile"><img src={ProfileIcon} />MyProfile</NavLink></li>
      <li className="help"><a href={help} target="_blank"><img src={HelpIcon} />Help</a></li>
      <li className="logout"><a href={logout}><img src={LogoutIcon} />Logout</a></li>
    </StyledNavigation>
  );
};

export default Navigation;
