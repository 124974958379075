import { readFeatureFlags } from './store';

const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  ADD_EXTERNAL_CPD_11361: false,
  API_LEARNER_POST_CREATES: true,
  CASE_9228: false,
  COMPLETION_SUMMARY_CASE_9072: true,
  DISPLAY_EXTERNAL_CPD_11361: false,
  DISPLAY_AI_ROLEPLAY_11660: false,
  DISPLAY_UPDATED_CHATBOT_11328: false,
  RECOMMENDATIONS: true,
  REVIEWABLE_CASE_10297: false,
  SPLIT_OUT_FEATURE_API_10126: false,
  SPLIT_OUT_MAGENTO_API_10125: false,
  SPLIT_OUT_SUPPORT_API_10126: false,
  SPLIT_OUT_THIRD_PARTY_API_10125: false,
  SYNC_MAGENTO_COURSE_V2_CASE_11076: false,
  USE_BACKUP_ACCOUNT_10640: true,
  USE_COURSE_CHAT_AUTH_10705: false,
  USE_COURSE_IFRAMES_CASE_9404: false,
  USE_DRUPAL: false,
  USE_GENAI_COURSE_COMPLETION_EMAIL_10838: false,
  USE_LEARNER_DASHBOARD: true,
  USE_MAILCHIMP_CASE_5763: true,
  USE_MAILCHIMP_CASE_9296: false,
  USE_MAILCHIMP_CPD_YEAR_CASE_10884: false,
  USE_UPLOADED_REPORTS_10765: false,
  USE_ADDITIONAL_MATERIALS_11602: false,
};

/****************************************************************************
 * Please remember to make feature flag names descriptive.
 * NOUN_CASE_####
 * VERB_NOUN_CASE_#### - where verb is not USE, ACTIVATE, TURN_ON, SHOW etc
 * True should be on, false, off.
 * Try to describe the feature, rather than mention a technology.
 * Feature flag naming issues are P1 or P2 in code review.
 */

export interface FeatureFlags {
  ADD_EXTERNAL_CPD_11361: boolean;
  API_LEARNER_POST_CREATES: boolean;
  CASE_9228: boolean;
  COMPLETION_SUMMARY_CASE_9072: boolean;
  DISPLAY_EXTERNAL_CPD_11361: boolean;
  DISPLAY_AI_ROLEPLAY_11660: boolean;
  DISPLAY_UPDATED_CHATBOT_11328: boolean;
  RECOMMENDATIONS: boolean;
  REVIEWABLE_CASE_10297: boolean;
  SPLIT_OUT_FEATURE_API_10126: boolean;
  SPLIT_OUT_MAGENTO_API_10125: boolean;
  SPLIT_OUT_SUPPORT_API_10126: boolean;
  SPLIT_OUT_THIRD_PARTY_API_10125: boolean;
  SYNC_MAGENTO_COURSE_V2_CASE_11076: boolean;
  USE_BACKUP_ACCOUNT_10640: boolean;
  USE_COURSE_CHAT_AUTH_10705: boolean;
  USE_COURSE_IFRAMES_CASE_9404: boolean;
  USE_DRUPAL: boolean;
  USE_GENAI_COURSE_COMPLETION_EMAIL_10838: boolean;
  USE_LEARNER_DASHBOARD: boolean;
  USE_MAILCHIMP_CASE_5763: boolean;
  USE_MAILCHIMP_CASE_9296: boolean;
  USE_MAILCHIMP_CPD_YEAR_CASE_10884: boolean;
  USE_UPLOADED_REPORTS_10765: boolean;
  USE_ADDITIONAL_MATERIALS_11602: false,
}

export type FeatureFlagsLayer = Partial<FeatureFlags>;

export type FeatureFlag = keyof FeatureFlags

export const
  API_LEARNER_POST_CREATES = 'API_LEARNER_POST_CREATES',
  CASE_9228 = 'CASE_9228',
  COMPLETION_SUMMARY_CASE_9072 = 'COMPLETION_SUMMARY_CASE_9072',
  RECOMMENDATIONS = 'RECOMMENDATIONS',
  REVIEWABLE_CASE_10297 = 'REVIEWABLE_CASE_10297',
  SPLIT_OUT_SUPPORT_API_10126 = 'SPLIT_OUT_SUPPORT_API_10126',
  SPLIT_OUT_FEATURE_API_10126 = 'SPLIT_OUT_FEATURE_API_10126',
  SPLIT_OUT_THIRD_PARTY_API_10125 = 'SPLIT_OUT_THIRD_PARTY_API_10125',
  SPLIT_OUT_MAGENTO_API_10125 = 'SPLIT_OUT_MAGENTO_API_10125',
  SYNC_MAGENTO_COURSE_V2_CASE_11076 = "SPLIT_OUT_MAGENTO_API_10125",
  USE_BACKUP_ACCOUNT_10640 = 'USE_BACKUP_ACCOUNT_10640',
  USE_DRUPAL = 'USE_DRUPAL',
  USE_LEARNER_DASHBOARD = 'USE_LEARNER_DASHBOARD',
  USE_COURSE_CHAT_AUTH_10705 = 'USE_COURSE_CHAT_AUTH_10705',
  USE_COURSE_IFRAMES_CASE_9404 = 'USE_COURSE_IFRAMES_CASE_9404',
  USE_MAILCHIMP_CASE_5763 = 'USE_MAILCHIMP_CASE_5763',
  USE_MAILCHIMP_CASE_9296 = 'USE_MAILCHIMP_CASE_9296',
  USE_GENAI_COURSE_COMPLETION_EMAIL_10838 = 'USE_GENAI_COURSE_COMPLETION_EMAIL_10838',
  USE_MAILCHIMP_CPD_YEAR_CASE_10884 = "USE_MAILCHIMP_CPD_YEAR_CASE_10884",
  USE_UPLOADED_REPORTS_10765 = "USE_UPLOADED_REPORTS_10765",
  ADD_EXTERNAL_CPD_11361 = "ADD_EXTERNAL_CPD_11361",
  DISPLAY_EXTERNAL_CPD_11361 = "DISPLAY_EXTERNAL_CPD_11361",
  DISPLAY_UPDATED_CHATBOT_11328 = "DISPLAY_UPDATED_CHATBOT_11328",
  USE_ADDITIONAL_MATERIALS_11602 = "USE_ADDITIONAL_MATERIALS_11602",
  DISPLAY_AI_ROLEPLAY_11660 = "DISPLAY_AI_ROLEPLAY_11660";

export type FeatureFlagLevels = {
  env?: FeatureFlagsLayer;
  override?: FeatureFlagsLayer;
  user?: FeatureFlagsLayer;
  session?: FeatureFlagsLayer;
};

export function featureFlagLevelsFromUnsafe(unsafe): FeatureFlagLevels {
  const
    warnings: string[] = [],
    ret: FeatureFlagLevels = {};

  if (unsafe && typeof unsafe === 'object') {
    const { env, override, user, session } = unsafe;
    if (env) { ret.env = featureFlagsFromUnsafe(env); }
    if (user) { ret.user = featureFlagsFromUnsafe(user); }
    if (session) { ret.session = featureFlagsFromUnsafe(session); }
    if (override) { ret.override = featureFlagsFromUnsafe(override); }
  }
  else {
    warnings.push('featureFlagLevel is not an object');
  }

  if (warnings.length) {
    console.log('WARNINGS', warnings);
  }

  return ret;
}

export function featureFlagLevelsFromMerge(...ffL: FeatureFlagLevels[]): FeatureFlagLevels{
  const
    ret: FeatureFlagLevels = {},
    env = featureFlagsFromMerge(...ffL.map(d => d.env || {})),
    user = featureFlagsFromMerge(...ffL.map(d => d.user || {})),
    session = featureFlagsFromMerge(...ffL.map(d => d.session || {})),
    override = featureFlagsFromMerge(...ffL.map(d => d.override || {}));

  if (Object.keys(env).length) { ret.env = env; }
  if (Object.keys(user).length) { ret.user = user; }
  if (Object.keys(session).length) { ret.session = session; }
  if (Object.keys(override).length) { ret.override = override; }
  return ret;
}

export function checkForFeatureFlagDeletion(ff: Partial<FeatureFlags>){
  const flags = Object.keys(ff);


  const ret = flags.reduce((p, c) => {
    const flag = ff[c];
    // Skip null values as something to delete or remove
    if(flag === null){
      return p;
    }

    p[c] = flag;
    return p;
  }, <Partial<FeatureFlags>>{});

  return ret;
}

export function featureFlagsFromMerge(...ff: Partial<FeatureFlags>[]): Partial<FeatureFlags> {
  return Object.assign({}, ...ff);
}

export function featureFlagsFromUnsafe(unsafe): Partial<FeatureFlags> {
  let err: string, ret: Partial<FeatureFlags> = {};
  const
    errs: string[] = [],
    flags = Object.keys(getDefaultFeatureFlags()),
    warnings: string[] = [];

  if (unsafe && typeof unsafe === 'object') {
    if (err = isKeys(Object.keys(unsafe).filter(flag => !flags.includes(flag)), 'featureFlags')) { warnings.push(err); }
    flags.forEach(flag => {
      if (flag in unsafe) {
        ret[flag] = unsafe[flag];

        // Checks if flag is boolean or null
        if ((err = isBoolean(ret[flag], 'featureFlags.' + flag) ) && ret[flag] !== null) { errs.push(err); }
      }
    });
  } else {
    warnings.push('featureFlags is not an object');
  }

  if (warnings.length) {
    console.log('WARNINGS', warnings);
  }

  if (errs.length) {
    console.log('ERRORS', errs);
    throw new Error('ERRORS:\n' + errs.join('\n'));
  }

  return ret;
}

export function getDefaultFeatureFlags(): FeatureFlags {
  return Object.assign({}, DEFAULT_FEATURE_FLAGS);
}

export async function getFeatureFlags(
  table: string,
  userFeatureFlags: FeatureFlagsLayer = {},
  sessionFeatureFlags: FeatureFlagsLayer = {}
): Promise<FeatureFlags> {
  let
    environmentFeatureFlags = {},
    overrideFeatureFlags = {};
  try {
    [environmentFeatureFlags, overrideFeatureFlags] = await Promise.all([readFeatureFlags(table, 'environment'), readFeatureFlags(table, 'override')]);
  }
  catch(e) {
    console.log('Something went wrong with fetching environment level feature flags');
  }
  finally {
    return getFullFeatureFlags({
      def: DEFAULT_FEATURE_FLAGS,
      environment: environmentFeatureFlags,
      user: userFeatureFlags,
      override: overrideFeatureFlags,
      session: sessionFeatureFlags
    });
  }
}

export function featureFlagsToDeploy(featureFlags: FeatureFlags, featureFlagsToDeploy: string[]): Partial<FeatureFlags> {
  return featureFlagsToDeploy.reduce((ret, featureFlag) => {
    ret[featureFlag] = featureFlags[featureFlag];
    return ret;
  }, {});
}

export function featureFlagFromPartial(io: Partial<FeatureFlags>): FeatureFlags {
  const
    {
      ADD_EXTERNAL_CPD_11361,
      API_LEARNER_POST_CREATES,
      CASE_9228,
      COMPLETION_SUMMARY_CASE_9072,
      DISPLAY_EXTERNAL_CPD_11361,
      DISPLAY_AI_ROLEPLAY_11660,
      DISPLAY_UPDATED_CHATBOT_11328,
      RECOMMENDATIONS,
      REVIEWABLE_CASE_10297,
      SPLIT_OUT_FEATURE_API_10126,
      SPLIT_OUT_MAGENTO_API_10125,
      SPLIT_OUT_SUPPORT_API_10126,
      SPLIT_OUT_THIRD_PARTY_API_10125,
      SYNC_MAGENTO_COURSE_V2_CASE_11076,
      USE_BACKUP_ACCOUNT_10640,
      USE_COURSE_CHAT_AUTH_10705,
      USE_COURSE_IFRAMES_CASE_9404,
      USE_DRUPAL,
      USE_GENAI_COURSE_COMPLETION_EMAIL_10838,
      USE_LEARNER_DASHBOARD,
      USE_MAILCHIMP_CASE_5763,
      USE_MAILCHIMP_CASE_9296,
      USE_MAILCHIMP_CPD_YEAR_CASE_10884,
      USE_UPLOADED_REPORTS_10765,
      USE_ADDITIONAL_MATERIALS_11602
    } = io,
    ioKeys = Object.keys(io),
    def = Object.keys(getDefaultFeatureFlags());

  const isCorrect = def.every((d) => {
    return ioKeys.includes(d);
  });

  if (isCorrect) {
    return {
      ADD_EXTERNAL_CPD_11361,
      API_LEARNER_POST_CREATES,
      CASE_9228,
      COMPLETION_SUMMARY_CASE_9072,
      DISPLAY_EXTERNAL_CPD_11361,
      DISPLAY_AI_ROLEPLAY_11660,
      DISPLAY_UPDATED_CHATBOT_11328,
      RECOMMENDATIONS,
      REVIEWABLE_CASE_10297,
      SPLIT_OUT_FEATURE_API_10126,
      SPLIT_OUT_MAGENTO_API_10125,
      SPLIT_OUT_SUPPORT_API_10126,
      SPLIT_OUT_THIRD_PARTY_API_10125,
      SYNC_MAGENTO_COURSE_V2_CASE_11076,
      USE_BACKUP_ACCOUNT_10640,
      USE_COURSE_CHAT_AUTH_10705,
      USE_COURSE_IFRAMES_CASE_9404,
      USE_DRUPAL,
      USE_GENAI_COURSE_COMPLETION_EMAIL_10838,
      USE_LEARNER_DASHBOARD,
      USE_MAILCHIMP_CASE_5763,
      USE_MAILCHIMP_CASE_9296,
      USE_MAILCHIMP_CPD_YEAR_CASE_10884,
      USE_UPLOADED_REPORTS_10765,
      USE_ADDITIONAL_MATERIALS_11602
    };
  }
  else {
    // Skip over unsolicited feature flags
    return def.reduce((p,c) => {
      if(ioKeys.includes(c)){
        p[c] = io[c];
      }
      return p;
    }, Object.assign({}, DEFAULT_FEATURE_FLAGS))
  }
}

export function getFullFeatureFlags(flags:{
  def?: FeatureFlagsLayer,
  environment?: FeatureFlagsLayer,
  user?: FeatureFlagsLayer,
  override?: FeatureFlagsLayer,
  session?: FeatureFlagsLayer
} = { def: DEFAULT_FEATURE_FLAGS }
): FeatureFlags {
  if(!flags.def)
    flags.def = DEFAULT_FEATURE_FLAGS;

  const
      { def, environment, user, override, session} = flags;

  return Object.assign({}, def, environment, user, override, session );
}

function isBoolean(val, info: string): string {
  if (val !== undefined) {
    if (typeof val !== 'boolean') {
      return info + ' must be a boolean';
    }
  } else {
    return info + ' must be specified';
  }
}

function isKeys(keys: string[], info: string): string {
  if (keys.length) {
    return info + ' contains unknown keys: ' + keys.join(', ');
  }
}

export function mapLegacyFeatureFlags(legacyFeatureFlags): FeatureFlagsLayer {
  const featureFlags = Object.assign({}, legacyFeatureFlags);
  if ('use_learner_dashboard' in featureFlags) {
    featureFlags.USE_LEARNER_DASHBOARD = featureFlags.use_learner_dashboard;
    delete featureFlags.use_learner_dashboard;
  }
  delete featureFlags.force_use_learner_dashboard;
  return featureFlags;
}
