
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { FirebaseAuthContext } from './firebase-auth';
import { RecaptchaVerifier, User as FirebaseUser} from 'firebase/auth';
import { enrollMFAPhoneNumber, loginWithCustomToken, loginWithEmailAndPassword,
  MFASignIn, sendPhoneVerification, signOutFromSystem, verifyPhoneAuthCodeEnrollment, verifyPhoneAuthCodeSignIn
} from '@lawcpd/firebase';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import FirebaseClientApp from 'libs/firebase/src/lib/firebase';
import { useFeatureFlags } from '@lawcpd/feature-flags/provider';

interface IFirebaseAuthProviderOptions {
  children: React.ReactElement;
}

export const FirebaseAuthProvider = ({ children } : IFirebaseAuthProviderOptions) => {
  const
    {
      mycpd: { api }, support: { api: supportApi }, cookiesDomain, support,
      support: { login }, firebase
    } = useEnvironment(),
    cookieOption : Cookies.CookieAttributes = {
      domain: cookiesDomain,
      path: '/',
      expires: new Date(-1),
      samesite: 'strict',
      secure: true
    },
    { loading: ffloading, featureFlags } = useFeatureFlags(),
    [error, setError] = useState(''),
    [enrollError, setEnrollError] = useState(''),
    [verifyError, setVerifyError] = useState(''),
    [loading, setLoading] = useState(true),
    [isMFASetUp, setIsMFASetUp] = useState(false),
    [user, setUser] = useState<FirebaseUser>(null),
    [mfaSignIn, setMFASignIn] = useState<MFASignIn>(null),
    [isAuthenticated, setIsAuthenticated] = useState(false),
    [firebaseApp, setFirebaseApp] = useState<FirebaseClientApp>();

  // Initialize Firebase Client App
  useEffect(()=>{
    if(!firebaseApp && !ffloading){
      const
        clientApp = new FirebaseClientApp(firebase.config),
        // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#onauthstatechanged
        // onAuthStateChanged() returns the unsubscribe function for the observer.
        unsubscribe = clientApp.getAuth().onAuthStateChanged((user) => {
          if(user){
            setIsAuthenticated(true);
            setUser(user);
            setLoading(false);
          }
          else{
            setLoading(false);
          }
        });

      setFirebaseApp(clientApp);

      return unsubscribe;
    }
  },[ffloading])

  const getCustomToken = (token: string) => {
    const
      endPoint = featureFlags.SPLIT_OUT_SUPPORT_API_10126 ? supportApi : api,
      rb = {
        data: token,
        group: 'support',
        name: 'token'
      },
      init: RequestInit = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(rb),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

    if(featureFlags.SPLIT_OUT_SUPPORT_API_10126){
      init.credentials = 'include';
    }

    return fetch(`${endPoint}/api/support/token`, init)
    .then((res) => {
      return res.text();
    })
  }

  const logInWithJWT = async (token: string) => {
    setLoading(true);
    return loginWithCustomToken(firebaseApp.getAuth(), token)
    .then((user) => {
      setUser(user);
      setLoading(false);
      setIsAuthenticated(true);
      return user.getIdToken();
    })
    .catch((e) => {
      return Promise.reject(e);
    });
  }

  const logInWithEmail = (email: string, password: string, recaptcha: RecaptchaVerifier) => {
    setLoading(true);
    setError('');

    loginWithEmailAndPassword(firebaseApp.getAuth(), email, password, recaptcha)
    .then((res) => {
      // Logged in but need to enroll first to 2FA
      if((res as FirebaseUser).email){
        setIsMFASetUp(false);
        setUser(res as FirebaseUser);
      }
      // MFA Log in
      else if((res as MFASignIn).resolver && (res as MFASignIn).verificationId){
        setIsMFASetUp(true);
        setMFASignIn((res as MFASignIn));
      }
      setLoading(false);
    })
    .catch((e) => {
      console.error(e.message);
      setError(e.message);
      setLoading(false);
    });
  }

  const logOut = () => {
    signOutFromSystem(firebaseApp.getAuth())
    .then(() => {
      setUser(null);
      setLoading(false);
      setMFASignIn(null);
      setIsMFASetUp(false);
      setIsAuthenticated(false);
      Cookies.remove('_fbt', cookieOption);
      Cookies.remove('fbt', cookieOption);
      window.top.location.assign(login)
    })
    .catch((e) => {
      console.error(e);
      setError(e.message);
      setLoading(false);
    })
  }

  const enrollUserToPhoneMFA = (phonenumber: string, recaptcha: RecaptchaVerifier) => {
    if(user){
      // setLoading(true);
      return enrollMFAPhoneNumber(firebaseApp.getAuth(), user, phonenumber, recaptcha)
      .then((verificationId) => {
        setEnrollError('');
        setLoading(false);
        return verificationId;
      })
      .catch((e) => {
        setEnrollError(e.message);
        setLoading(false);
      });
    }
  }

  const verifyEnrollmentPhoneCode = (enrollmentId: string, code: string) => {
    if(user){
      setLoading(true);
      verifyPhoneAuthCodeEnrollment(user, enrollmentId, code)
      .then(() => {
        setEnrollError('');
        setLoading(false);
        redirectAfterAuth(user);
      })
      .catch((e) => {
        setLoading(false);
        setEnrollError(e.message);
        setIsAuthenticated(false);
      })
    }
  }

  const verifyMFAPhoneCode = (code: string) => {
    if(mfaSignIn){
      setLoading(true);
      verifyPhoneAuthCodeSignIn(mfaSignIn.resolver, mfaSignIn.verificationId, code)
      .then((userCredential) => {
        setError('');
        setLoading(false);
        setUser(userCredential.user)
        setIsAuthenticated(true);
        redirectAfterAuth(userCredential.user);
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false);
        setIsAuthenticated(false);
      })
    }
  }

  const resendPhoneCodeSignIn = (recaptcha: RecaptchaVerifier) => {
    const phoneInfoOptions = {
      multiFactorHint: mfaSignIn.resolver.hints[0],
      session: mfaSignIn.resolver.session
    }
    setLoading(true);
    sendPhoneVerification(firebaseApp.getAuth(), phoneInfoOptions, recaptcha)
    .then((verificationId) => {
      setLoading(false);
      setVerifyError('');
      setMFASignIn({
        resolver: mfaSignIn.resolver,
        verificationId: verificationId
      });
    })
    .catch((e) => {
      setLoading(false);
      setVerifyError(e);
    })
  }

  const redirectAfterAuth = (user: FirebaseUser) => {
    const before_auth = Cookies.get('before_auth_url');
    setLoading(true);
    user.getIdToken(/**force refresh */true)
    .then((token) => {
      const origin = before_auth ? (new URL(before_auth)).origin : support.url;

      setLoading(false);
      document.cookie = `_fbt=${token};`
      + `domain=${cookiesDomain};`
      + 'path=/;'
      + 'samesite=strict; secure;';

      window.top.location.assign(origin)
    })
    .catch((e) => {
      console.error('Error occured while retrieving the user token', e);
      setError(e.message);
      logOut();
    });
  }

  return (
    <FirebaseAuthContext.Provider
      value={{
        user,
        error,
        enrollError,
        verifyError,
        loading,
        isMFASetUp,
        firebaseApp,
        isAuthenticated,
        logOut,
        logInWithJWT,
        logInWithEmail,
        getCustomToken,
        verifyMFAPhoneCode,
        enrollUserToPhoneMFA,
        resendPhoneCodeSignIn,
        verifyEnrollmentPhoneCode
      }}
      >
      {children}
    </FirebaseAuthContext.Provider>
  )
}
