import React, { useContext } from 'react';
import { RecaptchaVerifier, User as FirebaseUser} from 'firebase/auth';
import FirebaseClientApp from 'libs/firebase/src/lib/firebase';
interface IFirebaseAuthContext {
  user: FirebaseUser
  error: string;
  enrollError: string;
  verifyError: string;
  loading: boolean;
  isMFASetUp: boolean;
  firebaseApp: FirebaseClientApp,
  isAuthenticated: boolean;
  logOut: () => void;
  logInWithJWT: (t: string) => Promise<string>;
  logInWithEmail: (e: string, p: string, r: RecaptchaVerifier) => void;
  getCustomToken: (t: string) => Promise<string>;
  verifyMFAPhoneCode: (p: string) => void;
  enrollUserToPhoneMFA: (p: string, r: RecaptchaVerifier) => Promise<string|void>;
  resendPhoneCodeSignIn: (r: RecaptchaVerifier) => void;
  verifyEnrollmentPhoneCode: (i: string, p: string) => void;
}

export const FirebaseAuthContext = React.createContext<IFirebaseAuthContext | null>(null);
export const useFirebaseAuth = () => useContext(FirebaseAuthContext);
