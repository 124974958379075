import { useFeatureFlags } from '@lawcpd/feature-flags/provider';
import { SearchString, SearchRefinement } from '@lawcpd/learner/shared/data';
import { joinValuesIntoQueryForm, mapValuesBasedOnKeys } from '@lawcpd/learner/shared/filter';
import React, { useEffect, useState } from 'react';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { EventLogsContext } from './event-logs';

interface ILearnersProviderOptions {
  children: React.ReactElement;
}

export const EventLogsProvider = ({
  children,
}: ILearnersProviderOptions) =>{
  const
    { user } = useFirebaseAuth(),
    { cookiesDomain, mycpd: { api }, support: { api: supportApi } } = useEnvironment(),
    { featureFlags, loading: ffLoading, error : ffError, } = useFeatureFlags(),
    [ limit, setLimit ] = useState(10),
    [ searchStr, setSearchStr ] = useState(''),
    [ refineStr, setRefineStr ] = useState(''),
    [ searchType, setSearchType ] = useState('AND'),
    [ eventLogs, setEventLogs ] = useState([]),
    [ eventLogsRes, setEventLogsRes ] = useState([]),
    [ lastKeys, setLastKeys ] = useState([{}]),
    [ searchTerms, setSearchTerms ] = useState<SearchString[]>([]),
    [ searchRefinements, setSearchRefinements ] = useState<SearchRefinement[]>([]),
    [ error, setError ] = useState(false),
    [ loading, setLoading ] = useState(true);

  /**
   * Requests from the learner api for a list of events
   *
   * @param limit The limit of events to be displayed, defaults to 10
   * @param lastKey The last learner retrieved, when not defined, assumes a
   * 1st page search
   * @returns A promise of list of events and the last learner from the list
   */
 const reqEvents = (limit: number = 10, lastKey: string) => {

    const
      endPoint = featureFlags.SPLIT_OUT_SUPPORT_API_10126 ? supportApi : api,
      eventsApi = `${endPoint}/api/support/event`;

    let eventsEndPoint = `${eventsApi}?l=${limit}`;
    eventsEndPoint = lastKey ? `${eventsEndPoint}&lk=${encodeURIComponent(lastKey)}` : eventsEndPoint;
    eventsEndPoint = searchStr ? `${eventsEndPoint}&sq=${encodeURIComponent(searchStr)}` : eventsEndPoint;
    eventsEndPoint = refineStr ? `${eventsEndPoint}&r=${encodeURIComponent(refineStr)}` : eventsEndPoint;
    eventsEndPoint = searchType ? `${eventsEndPoint}&t=${encodeURIComponent(searchType)}` : eventsEndPoint;

    return user.getIdToken()
    .then((token) => {
      document.cookie = 'fbt=' + token+ '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
      const init = {
        headers: { Authorization: `Bearer ${token}` }
      };

      if(featureFlags.SPLIT_OUT_SUPPORT_API_10126){
        init["credentials"] = "include";
      }

      return fetch(eventsEndPoint, init);
    })
    .then( _ => _.json())
    .then( res => {
      return {
        ...res
      }
    })
    .catch(e => e);
  };

  const sendReqEvents = (last: string = '') => {
    setLoading(true);
    reqEvents(limit, last)
      .then( res => {
        const { items, lastKey } = res;

        if(items && items.length > 0){
          setEventLogs(items.slice(0, limit));
          setEventLogsRes(items);
        }
        if(lastKey && lastKeys.indexOf(lastKey) === -1) setLastKeys([...lastKeys, lastKey]);
        setLoading(false);
      })
      .catch( e => {
        setLoading(false);
        setError(e);
      });
  }

  const getEvents = (page: number) => {
    const
      begin = page * limit,
      end = (page + 1) * limit,
      currentPagedEvents = eventLogsRes.slice(begin, end);

    if(currentPagedEvents.length > 0 && eventLogsRes.length > limit){
      setEventLogs(currentPagedEvents);
      return;
    }

    const lastKey = lastKeys[page]
      ? lastKeys[page]
      : lastKeys[lastKeys.length -1]
      ? lastKeys[lastKeys.length -1]
      : {};

    sendReqEvents(Object.keys(lastKey).length > 0 ? JSON.stringify(lastKey) : '');
  };

  const limitHandler = (input: number = limit) => {
    setLimit(input);
    setEventLogs([]);
    setLastKeys([{}]);
  };


  // Table initialisation and on pagination changed
  useEffect(() =>{
    if(user && !ffLoading && !ffError){
      getEvents(0);
    }
  }, [user, limit, searchStr, refineStr, ffLoading]);


  // Search Handler
  useEffect(() => {
    const
      groupedInputs = joinValuesIntoQueryForm(mapValuesBasedOnKeys(searchTerms, 'criteria', 'value')),
      groupedRefinements = joinValuesIntoQueryForm(mapValuesBasedOnKeys(searchRefinements, 'category', 'value')),
      inputQuery = groupedInputs.join('||'),
      inputRefine = groupedRefinements.join('||');

    setSearchStr(inputQuery);
    setRefineStr(inputRefine);
    setEventLogs([]);
    setLastKeys([{}]);
  },[searchTerms, searchRefinements]);

  return (
    <EventLogsContext.Provider
      value={{
        limit,
        searchTerms,
        searchRefinements,
        searchType,
        setSearchTerms,
        setSearchRefinements,
        setSearchType,
        getEvents,
        limitHandler,
        error,
        loading,
        eventLogs
      }}>
        {children}
    </EventLogsContext.Provider>
  )
}

export default EventLogsProvider
