import React, { useEffect, useState } from 'react';
import { SearchString } from '@lawcpd/learner/shared/data';
import { useEnvironment } from '../environment/environment';
import { useFirebaseAuth } from 'support-provider';

import { joinValuesIntoQueryForm, mapValuesBasedOnKeys } from '@lawcpd/learner/shared/filter'
import { LearnersContext } from './learners';

interface ILearnersProviderOptions {
  children: React.ReactElement;
}

export const LearnersProvider = ({
  children,
}: ILearnersProviderOptions) =>{
  const
    { user } = useFirebaseAuth(),
    { cookiesDomain, mycpd: { api } } = useEnvironment(),
    [ limit, setLimit ] = useState(10),
    [ searchStr, setSearchStr ] = useState(''),
    [ learners, setLearners ] = useState([]),
    [ learnersRes, setLearnersRes ] = useState([]), // Result of the last request that has been paged
    [ lastKeys, setLastKeys ] = useState<object[]>([{}]),
    [ searchTerms, setSearchTerms ] = useState<SearchString[]>([]),
    [ error, setError ] = useState(false),
    [ loading, setLoading ] = useState(true);

  /**
   * Requests from the learner api for a list of learners
   *
   * @param limit The limit of learners to be displayed, defaults to 100
   * @param lastKey The last learner retrieved, when not defined, assumes a
   * 1st page search
   * @returns A promise of list of learners and the last learner from the list
   */
 const reqLearners = (limit: number = 10, lastKey: string) => {
    const learnersApi = `${api}/api/learner?eventName=readPage`;

    let endPoint = `${learnersApi}&l=${limit}`;
    endPoint = lastKey ? `${endPoint}&lk=${encodeURIComponent(lastKey)}` : endPoint;
    endPoint = searchStr ? `${endPoint}&sq=${encodeURIComponent(searchStr)}` : endPoint;

    return user.getIdToken(true)
    .then((token) => {
      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
      const init = { headers: { Authorization: `Bearer ${token}` } };
      return fetch(endPoint, init);
    })
    .then( _ => _.json())
    .then( res => {
      return {
        ...res
      }
    })
    .catch(e => e);
  };

  const sendReqLearners  = (last: string = '') => {
    setLoading(true);
    reqLearners(limit, last)
      .then( res => {
        const { items, lastKey } = res;
        if(items && items.length > 0){
          setLearners(items.slice(0, limit));
          setLearnersRes(items);
        }
        if(lastKey && lastKeys.indexOf(lastKey) === -1) setLastKeys([...lastKeys, lastKey]);
        setLoading(false);
      })
      .catch( e => {
        setLoading(false);
        setError(e);
      });
  }

  const getLearners = (page: number) => {
    const
      begin = page * limit,
      end = (page + 1) * limit,
      currentPagedLearners = learnersRes.slice(begin, end);

    if(currentPagedLearners.length > 0 && learnersRes.length > limit){
      setLearners(currentPagedLearners);
      return;
    }

    const lastKey = lastKeys[page]
      ? lastKeys[page]
      : lastKeys[lastKeys.length -1]
      ? lastKeys[lastKeys.length -1]
      : {};

    sendReqLearners(Object.keys(lastKey).length > 0 ? JSON.stringify(lastKey) : '');
  };

  const limitHandler = (input: number = limit) => {
    setLimit(input);
    setLearners([]);
    setLastKeys([{}]);
  };

  // Table initialisation and on pagination changed
  useEffect(() =>{
    if(user){
      getLearners(0);
    }
  }, [user, limit, searchStr]);

  useEffect(() => {
    const
      groupedInputs = joinValuesIntoQueryForm(mapValuesBasedOnKeys(searchTerms, 'criteria', 'value')),
      inputQuery = encodeURIComponent(groupedInputs.join('||'));

    setSearchStr(inputQuery);

    setLearners([]);
    setLastKeys([{}]);
  },[searchTerms]);

  return (
    <LearnersContext.Provider
      value={{
        limit,
        searchTerms,
        setSearchTerms,
        getLearners,
        limitHandler,
        error,
        loading,
        learners
      }}>
        {children}
    </LearnersContext.Provider>
  )
}

export default LearnersProvider
