import { IoExternalCpd } from '@lawcpd/learner/shared/data';
import { ExternalCpdContext } from './external-cpd';

export const StorybookExternalCpdRecordsProvider = (Story, context) => {
  const { externalCpdRecordsError, externalCpdRecordsLoading, records } = context.args;

  return (
    <ExternalCpdContext.Provider
      value={{
        records: records,
        error: externalCpdRecordsError,
        loading: externalCpdRecordsLoading,
        deleteRecord: () => {}
      }}
    >
      <Story/>
    </ExternalCpdContext.Provider>
  );
};

type ExternalCpdRecordsArgTypes = {
  name: string;
  control: "object" | "boolean",
  table: {
    category: "External CPD Records"
    subcategory?: "States"
  }
}

export interface IStoryExternalCpdRecordsContext {
  records: IoExternalCpd[],
  externalCpdRecordsLoading: boolean,
  externalCpdRecordsError: boolean,
}

export const StoryBookExternalCpdRecordsArgs: IStoryExternalCpdRecordsContext = {
  records: [],
  externalCpdRecordsLoading: false,
  externalCpdRecordsError: false,
}

export const StoryBookExternalCpdRecordsArgTypes: Record<keyof IStoryExternalCpdRecordsContext, ExternalCpdRecordsArgTypes> = {
  records : {
    name: "External CPD Records",
    control: "object",
    table: {
      category: "External CPD Records"
    }
  },
  externalCpdRecordsLoading : {
    name: "Loading?",
    control: "boolean",
    table: {
      category: "External CPD Records",
      subcategory: "States"
    }
  },
  externalCpdRecordsError : {
    name: "Error?",
    control: "boolean",
    table: {
      category: "External CPD Records",
      subcategory: "States"
    }
  }
};

export default StorybookExternalCpdRecordsProvider;
