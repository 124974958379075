import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import {
  getDefaultFeatureFlags, getFullFeatureFlags, FeatureFlags, FeatureFlagsLayer, mapLegacyFeatureFlags,
} from '@lawcpd/feature-flags';
import { useEnvironment, useLearner } from '@lawcpd/learner/shared/provider';
import { FeatureFlagsContext } from './feature-flags';

/* eslint-disable-next-line */
export interface FeatureFlagsProviderProps {}

type IFeatureFlagsProviderOptions = {
  useLearnerFF?: boolean;
  children: React.ReactElement;
}

export const FeatureFlagsProvider = ({
  useLearnerFF = true,
  children,
}: IFeatureFlagsProviderOptions) => {
  const
    { development, featureFlag: { flagsUrl }} = useEnvironment(),
    { featureFlags: learnerFeatureFlags } = useLearner(useLearnerFF),
    [environmentFeatureFlags, setEnvironmentFeatureFlags] = useState<FeatureFlagsLayer>(),
    [error, setError] = useState(false),
    [featureFlags, setFeatureFlags] = useState<FeatureFlags>(getDefaultFeatureFlags()),
    [loading, setLoading] = useState(true),
    [overrideFeatureFlags, setOverrideFeatureFlags] = useState<FeatureFlagsLayer>();

  useEffect(() => {
    if(!development){
      fetch(`${flagsUrl}/environment.json`)
      .then( _ => _.json())
      .then(featureFlags => setEnvironmentFeatureFlags(featureFlags))
      .catch(e => {
        setError(true);
      });

    fetch(`${flagsUrl}/override.json`)
      .then( _ => _.json())
      .then(featureFlags => setOverrideFeatureFlags(featureFlags))
      .catch(e => {
        setError(true);
      });
    }
    else{
      setEnvironmentFeatureFlags({});
      setOverrideFeatureFlags({});
    }
  }, []);

  useEffect(() => {
    if (environmentFeatureFlags && (learnerFeatureFlags || !useLearnerFF) && overrideFeatureFlags) {
      let sessionFeatureFlags = {};
      if (Cookies.get('feature-flags')) {
        try {
          sessionFeatureFlags = JSON.parse(Cookies.get('feature-flags'));
        } catch(e) {
          setError(true);
        }
      }
      setFeatureFlags(getFullFeatureFlags({
        def: getDefaultFeatureFlags(),
        environment: environmentFeatureFlags,
        user: mapLegacyFeatureFlags(learnerFeatureFlags),
        override: overrideFeatureFlags,
        session: sessionFeatureFlags,
      }));
      setLoading(false);
    }

    // Comparing objects as a dependency array is asking for infinite re-rendering,
    // as such stringify them as deep comparison between objects is not something useEffect does for the dependency array
  }, [
    JSON.stringify(environmentFeatureFlags),
    JSON.stringify(learnerFeatureFlags),
    JSON.stringify(overrideFeatureFlags)
  ]);

  return (
    <FeatureFlagsContext.Provider
      value={{
        error,
        featureFlags,
        loading,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;
