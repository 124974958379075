import React from "react";
import { IdToken, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { JWT } from '@lawcpd/learner/shared/data';
import { Auth0Context } from './auth0';

interface IDevelopmentAuth0ProviderOptions {
  children: React.ReactElement;
  isAuthenticated?: boolean;
  loading?: boolean;
  user: JWT;
}

const
  FILE = 'development-auth0-provider',
  promiseIdToken = async (): Promise<IdToken> => { console.log(FILE + ':promiseIdToken()'); return { __raw: '' }; },
  promiseRedirectLoginResult = async (): Promise<RedirectLoginResult> => { console.log(FILE + ':promiseRedirectLoginResult()'); return null; },
  promiseString = async (): Promise<string> => { console.log(FILE + ':promiseString()'); return null; },
  promiseVoid = async (): Promise<void> => {};

export const DevelopmentAuth0Provider = ({
  children,
  isAuthenticated,
  loading,
  user,
}: IDevelopmentAuth0ProviderOptions) => {
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated: isAuthenticated === false ? false : true,
        user,
        loading: loading ? true : false,
        popupOpen: false,
        loginWithPopup: promiseVoid,
        handleRedirectCallback: promiseRedirectLoginResult,
        getIdTokenClaims: promiseIdToken,
        loginWithRedirect: promiseVoid,
        getTokenSilently: promiseString,
        getTokenWithPopup: promiseString,
        logout: promiseVoid,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
