import { useMediaQuery } from 'react-responsive';

const
  small = 520,
  medium = 760,
  large = 960,
  xlarge = 1600,
  smallDevice = `(min-width: ${small}px)`,
  mediumDevice = `(min-width: ${medium}px)`,
  largeDevice = `(min-width: ${large}px)`,
  xLargeDevice = `(min-width: ${xlarge}px)`;

const
  buttonWidthSize = 12.6,
  buttonWidthUnit = 'rem',
  buttonWidthDouble = `${buttonWidthSize * 2}${buttonWidthUnit}`,
  gutterSize = 10.8;

export const theme = {
  blue: '#396ab3',
  blueDark: '#2f4f93',
  blueDarkest: '#263573',
  blueDarkestDark: '#1f6ada',
  boxShadow: '0.2rem 0.6rem 0.9rem -0.3rem rgba(0,0,0,0.1)',
  buttonWidth: `${buttonWidthSize}${buttonWidthUnit}`,
  buttonWidth125: `${buttonWidthSize * 1.25}${buttonWidthUnit}`,
  buttonWidth15: `${buttonWidthSize * 1.5}${buttonWidthUnit}`,
  buttonWidthDouble: buttonWidthDouble,
  buttonWidthHalf: `${buttonWidthSize / 2}${buttonWidthUnit}`,
  componentPadding: '0.8rem 2rem 2rem',
  easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  extraLargeDevice: xLargeDevice,
  font: '#3f3f3c',
  gap: '2.4rem',
  gold: '#fc0',
  greyDark: '#b2b1ad',
  grey: '#f2f1ed',
  greyLight: '#f5f4f1',
  greyLightest: '#f6f4f4',
  gutter: `${gutterSize}rem`,
  gutterDouble: `${gutterSize * 2}rem`,
  isExtraLargeDevice: () => useMediaQuery({ query: xLargeDevice }),
  isLargeDevice: () => useMediaQuery({ query: largeDevice }),
  isMediumDevice: () => useMediaQuery({ query: mediumDevice }),
  isSmallDevice: () => useMediaQuery({ query: smallDevice }),
  largeDevice: largeDevice,
  mediumDevice: mediumDevice,
  navBoxShadow: '0 -0.1rem 0.5rem rgba(0,0,0,0.1)',
  notLargeDevice: `(max-width: ${large - 1}px)`,
  orange: '#f37021',
  orangeDark: '#ef5f08',
  radius: '0.6rem',
  smallDevice: smallDevice,
  successNormal: '#009273',
  tableColumn: '0.25rem',
  tableRow: '1.2rem',
  textChooseMoreCpdCourses: 'Choose More CPD Courses',
  textChooseMyNextCourse: 'Choose My Next Course',
  textViewAllMyCoursesInProgress: 'View All My Courses in Progress',
  widthViewAllMyCoursesInProgress: buttonWidthDouble,
  version: 26,
};
