import { featureFlagFromPartial, FeatureFlags, FeatureFlagsLayer, getDefaultFeatureFlags, getFullFeatureFlags } from "@lawcpd/feature-flags";
import { useFeatureFlags as useActualFeatureFlags } from "@lawcpd/feature-flags/provider";
import { useEnvironment } from "@lawcpd/learner/shared/provider";
import { useFirebaseAuth } from "support-provider";
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from "react";

type IFeatureFlagsContext = {
  error: boolean;
  featureFlags: FeatureFlags;
  loading: boolean;
  deployFeatureFlags: (flags: FeatureFlagsLayer, level: string) => void,
  getFeatureFlags: (level?: string) => Promise<any>
}

type IFeatureFlagsProviderOptions = {
  children: React.ReactElement;
}

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

const getRequestBody = (token, method: Method, rb?: Object, ff?: FeatureFlagsLayer) => {

  const ret: any = {
    method: method,
    mode: 'cors',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  }

  if(typeof rb === 'object' && Object.getPrototypeOf(rb) === Object.prototype && Object.keys(rb).length > 0){
    ret.body = JSON.stringify(rb);
  }

  if(ff && ff.SPLIT_OUT_FEATURE_API_10126){
    ret.credentials = 'include';
  }

  return ret;
}

const FeatureFlagsContext = React.createContext<IFeatureFlagsContext | null>(null);

export const FeatureFlagsProvider = ( { children } : IFeatureFlagsProviderOptions) =>{
  const
    defaultFlags = getDefaultFeatureFlags(),
    { user } = useFirebaseAuth(),
    { loading: ffloading, featureFlags: actualFeatureFlags } = useActualFeatureFlags(),
    { mycpd: { api }, featureFlag: { api : ffApi }} = useEnvironment(),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(false),
    [dynamoFF, setDynamoFF] = useState<FeatureFlagsLayer>({}),
    [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFlags);

  useEffect(() =>{
    if(user && !ffloading){
      getFeatureFlags('default');
    }
  },[user, ffloading]);

  useEffect(() =>{
    if(Object.keys(dynamoFF).length > 0){
      setFeatureFlags(featureFlagFromPartial(dynamoFF));
    }
  }, [dynamoFF])

  const getFeatureFlags = (level: string = 'environment') => {
    setLoading(true);
    setError(false);
    const
      endPoint = actualFeatureFlags.SPLIT_OUT_FEATURE_API_10126 ? ffApi : api,
      ffEndpoint = `${endPoint}/api/feature-flags`,
      fFlagRead = `${ffEndpoint}`;

    if(level === 'session') {
      const
        cookieFlags = Cookies.get("feature-flags"),
        parsedFlags = cookieFlags ? JSON.parse(cookieFlags) : {};

      const fullFlags = getFullFeatureFlags({
        def: defaultFlags,
        session: parsedFlags
      });
      setFeatureFlags(fullFlags);
      setLoading(false);
      return Promise.resolve(fullFlags);
    }
    else if(level === 'default'){
      setFeatureFlags(defaultFlags);
      setLoading(false);
      return Promise.resolve(defaultFlags);
    }

    return user.getIdToken().then((idToken) =>{
      fetch(`${fFlagRead}?level=${level}`, getRequestBody(idToken, 'GET', {}, actualFeatureFlags))
      .then( _ => _.json())
      .then( fFlags => {
        setLoading(false);
        setDynamoFF(fFlags)
      })
      .catch(e => {
        console.error('Read feature flags:', e)
        setLoading(false);
        setError(true);
      });
    })
  }

  const deployFeatureFlags = (newFFlags: FeatureFlagsLayer, level = 'environment') =>{
    setLoading(true);
    const
      httpMethod = actualFeatureFlags.SPLIT_OUT_FEATURE_API_10126 ? 'PUT' : 'POST',
      endPoint = actualFeatureFlags.SPLIT_OUT_FEATURE_API_10126 ? ffApi : api,
      ffEndpoint = `${endPoint}/api/feature-flags`,
      fFlagRead = `${ffEndpoint}`,
      fFlagDeploy = actualFeatureFlags.SPLIT_OUT_FEATURE_API_10126 ? ffEndpoint : `${ffEndpoint}/deploy`,
      rb = {
        data: {
          flags: newFFlags,
          level: level
        },
        group:'feature-flags',
        name: actualFeatureFlags.SPLIT_OUT_FEATURE_API_10126 ? 'update' : 'deploy'
      };

    user.getIdToken().then((idToken) =>{
      fetch(fFlagDeploy, getRequestBody(idToken, httpMethod, rb, actualFeatureFlags))
        .then( _ => {
          return fetch(`${fFlagRead}?level=${level}`, getRequestBody(idToken, 'GET', {}, actualFeatureFlags));
        })
        .then( _ => _.json())
        .then( fFlags => {
          setLoading(false);
          setDynamoFF(fFlags)
        })
        .catch(e => {
          console.error('Deploy feature flags:', e)
          setLoading(false);
          setError(true);
        });
    })
  }

  return (
    <FeatureFlagsContext.Provider value={{
      error,
      featureFlags,
      loading,
      deployFeatureFlags,
      getFeatureFlags
    }}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
