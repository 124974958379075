import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { theme } from '../theme';

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: rgba(0,0,0, 0.75);
`;

export const StyledModal = styled.div`
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 0;
  bottom: 2%;
  right: 0;
  margin: auto;
  width: 80%;
  height: 70vh;
  z-index: 100;
  overflow-y: scroll;

  .modal-wrapper{
    position: relative;
    height: auto;
  }

  .modal-header{
    background: white;
    padding: 1rem;
  }

  .modal-header h2{
    margin: 0 auto;
    text-align: center;
  }

  .modal-content{
    padding: 1rem;
    height: 100%;
  }

  .modal-footer{
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  .modal-footer a{
    background-color: ${theme.orange};
    border: 1px solid ${theme.orangeDark};
    border-radius: ${theme.radius};
    margin: 0 ${theme.tableColumn};
    cursor: pointer;
    color: white;
    padding: 1.1rem 1.3rem;
    width: 100%;
    text-align: center;
    text-decoration: none;
    user-select: none;
    transition: 0.2s ${theme.easing} all;
  }

  .modal-footer a:active, a:hover{
    background-color: ${theme.orangeDark};
  }
`;

const StyledAlert = styled.div`
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 101;
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  border-radius: 10px;
  padding: 0 1em;
`;

export enum ModalPromptTypes {
  Prompt = 'prompt',
  Confirm = 'confrim'
}

interface IModalBackdrop {
  backdropId: string;
  overlayId: string;
  title?: string;
  type?: ModalPromptTypes;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  cancelText?: string;
  confirmText?: string;
  className?: string;
  children?: React.ReactElement | React.ReactElement[];
}

interface IAlert {
  overlayId: string;
  children: React.ReactElement | React.ReactElement[];
}

export const Backdrop = (props) => {
  return (
    <StyledBackdrop onClick={props.onClose}>
      {props.children}
    </StyledBackdrop>
  )
}

export const Alert = (props: IAlert) => {
  const { overlayId, children } = props;
  return (
    <div>
      {ReactDOM.createPortal(
        <StyledAlert>
          {children}
        </StyledAlert>,
        document.getElementById(overlayId)
      )}
    </div>
  )
}

export const Modal = (props) => {
  const type = props.type || 'prompt';

  const footerElems = () => {
    switch(type){
      case('prompt'):
        return(
          <footer className='modal-footer'>
            <a onClick={props.onConfirm}>{props.confirmText}</a>
          </footer>
        );
      case('confirm'):
        return(
          <footer className='modal-footer'>
            <a onClick={props.onConfirm}>{props.confirmText}</a>
            <a onClick={props.onCancel}>{props.cancelText}</a>
          </footer>
        );
      default:
        return;
    }
  };


  return (
    <StyledModal className={props.className}>
      <div className='modal-wrapper'>
        <header className='modal-header'>
            <h2>{props.title}</h2>
        </header>
        <div className='modal-content'>
          {props.children}
        </div>
        {footerElems()}
      </div>
    </StyledModal>
  )
}

export const ModalBackdrop = (props: IModalBackdrop) => {
  const {
    onConfirm, onCancel, onClose,
    backdropId, overlayId,
    title, type, confirmText,
    cancelText, className, children
  } = props;

  if(!backdropId && !overlayId) return;
  return (
    <div>
      {ReactDOM.createPortal(
        <Backdrop onClose={onClose}/>,
        document.getElementById(backdropId)
      )}
      {ReactDOM.createPortal(
        <Modal
          title={title}
          type={type}
          onCancel={onCancel}
          onConfirm={onConfirm}
          cancelText={cancelText}
          confirmText={confirmText}
          className={className}
        >
          {children}
        </Modal>,
        document.getElementById(overlayId)
      )}
    </div>
  )
}

export default ModalBackdrop;
