import React, { useState, useEffect, useContext } from "react";
import {
  createAuth0Client,
  Auth0Client,
  PopupLoginOptions,
  RedirectLoginResult,
  IdToken,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  Auth0ClientOptions,
} from "@auth0/auth0-spa-js";

import { useEnvironment } from '../environment/environment';
import { useNavigate } from "react-router-dom";

interface IAuth0Context {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup(options: PopupLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  getIdTokenClaims(): Promise<IdToken>;
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): void;
}

type PartialAuth0ClientOptions = Omit<Auth0ClientOptions, "clientId" | "domain">;

interface IAuth0ProviderOptions {
  children: React.ReactElement;
}

export const Auth0Context = React.createContext<IAuth0Context | null>({
  isAuthenticated: false,
  user: null,
  loading: true,
  popupOpen: false,
  loginWithPopup: (options: PopupLoginOptions): Promise<void> => Promise.resolve(),
  handleRedirectCallback: (): Promise<RedirectLoginResult> => Promise.resolve({}),
  getIdTokenClaims: (): Promise<IdToken> => Promise.resolve({ __raw: null }),
  loginWithRedirect: (o: RedirectLoginOptions): Promise<void> => Promise.resolve(),
  getTokenSilently: (o?: GetTokenSilentlyOptions): Promise<string | undefined> => Promise.resolve(""),
  getTokenWithPopup: (o?: GetTokenWithPopupOptions): Promise<string | undefined> => Promise.resolve(""),
  logout: (o?: LogoutOptions): void => {},
});
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  ...partialInitOptions
}: IAuth0ProviderOptions & PartialAuth0ClientOptions) => {
  const
    navigate = useNavigate(),
    { domain: appDomain, auth0: { clientId, domain } } = useEnvironment(),
    initOptions: Auth0ClientOptions = Object.assign({ clientId, domain: domain, cookieDomain: `.${appDomain}` }, partialInitOptions),
    [isAuthenticated, setIsAuthenticated] = useState(false),
    [user, setUser] = useState<any | null>(),
    [auth0Client, setAuth0] = useState<Auth0Client>(),
    [loading, setLoading] = useState(true),
    [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=") &&
          window.location.search.includes("state=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        navigate(appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
        );
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    const result = await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
    return result;
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: () =>
          auth0Client!.getIdTokenClaims(),
        loginWithRedirect: (o: RedirectLoginOptions) =>
          auth0Client!.loginWithRedirect(o),
        getTokenSilently: (o: GetTokenSilentlyOptions | undefined) =>
          auth0Client!.getTokenSilently(o),
        getTokenWithPopup: (o: GetTokenWithPopupOptions | undefined) =>
          auth0Client!.getTokenWithPopup(o),
        logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
