import React from "react";
import { AnnotatedAllocation } from '@lawcpd/learner/shared/data';
import { AllocationsContext } from "./allocations";

export const StorybookAllocationsProvider = (Story, context) => {
  const { allocations, allocationsLoading, allocationsError } = context.args;

  if(allocations){
    for (let i = 0; i < allocations.length; i++) {
      const
        allocation = allocations[i],
        course = allocation.course;

      if (allocation.data.started) {
        allocation.data.started =  allocation.data.started;
      }
      if (allocation.data.scored) {
        allocation.data.score =  allocation.data.score;
        allocation.data.scored =  allocation.data.scored;
      }
      allocation.metadata.created = allocation.metadata.created;
      allocation.data.progress = allocation.data.progress;
      allocation.metadata.updated = allocation.metadata.updated;
      if (allocation.data.completed) {
        allocation.data.completed = allocation.data.completed;
      }

      course.data.courseName = course.data.courseName;
      course.data.competency = course.data.competency;
      course.data.competencyType = course.data.competencyType;
      course.data.cpdPoints = course.data.cpdPoints;
      course.data.thumbnailUrl = course.data.thumbnailUrl;
      course.data.authorName = course.data.authorName;
      course.data.isLaunchable = course.data.isLaunchable;
      course.data.isPurchasable = course.data.isPurchasable;
      course.data.isReviewable = course.data.isReviewable;
    }
  }

  return (
    <AllocationsContext.Provider
      value={{
        allocations: allocations,
        error: allocationsError,
        loading: allocationsLoading,
      }}
    >
      <Story/>
    </AllocationsContext.Provider>
  );
};

type AllocationsArgTypes = {
  name: string;
  control: "object" | "boolean", // Object is default for array this is because of allocations
  table: {
    category: "Allocations";
    subcategory?: string;
  }
};

export interface IStoryAllocationsContext {
  allocations: AnnotatedAllocation[],
  allocationsLoading: boolean,
  allocationError: boolean,
}

export const StoryBookAllocationsArgs: IStoryAllocationsContext = {
  allocations: [],
  allocationsLoading: false,
  allocationError: false,
}

export const StoryBookAllocationsArgTypes: Record<keyof IStoryAllocationsContext, AllocationsArgTypes> = {
  allocations: {
    name: "Allocations",
    control: "object",
    table: {
      category: "Allocations"
    }
  },
  allocationError: {
    name: "Error?",
    control: "boolean",
    table: {
      category: "Allocations",
      subcategory: "States"
    }
  },
  allocationsLoading: {
    name: "Loading?",
    control: "boolean",
    table: {
      category: "Allocations",
      subcategory: "States"
    }
  }
}

export default StorybookAllocationsProvider;
