import styled from 'styled-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button } from '@lawcpd/learner/shared/ui';
import { useFeatureFlags } from './feature-flags/feature-flag-provider';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { featureFlagsToDeploy, FeatureFlags, getDefaultFeatureFlags } from '@lawcpd/feature-flags';

const StyledFFContainer = styled.div`{
  select {
    background-color: transparent;
    text-align: center;
    border: solid #dedcdc;
    border-radius: 10px;
    padding: 1% 5%;
    font-weight: bold;
  }

  .disabled-input {
    cursor: not-allowed;
  }
}`

const StyledSwitchContainer = styled.div`{
  top:20px;
  padding:10px;

  svg {
    vertical-align: middle;
    margin-right: 20px;
    cursor: pointer;
    stroke: #2196F3;
    fill: none;
  }

  div.inactive {
    display: none;
  }

  svg.active {
    transform: rotate(-90deg);
  }

  div.active {
    display: initial;
  }

}`;

const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  right: 20px;
  vertical-align: middle;
  margin-left: 20px;

  input { display: none; }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const StyledButtons = styled.div`
  display:flex;
  justify-content: space-around;
  width: 100%;
  height: 75px;

  button {
    flex-grow: 1;
    padding: 0;
    margin: 1.5em;
  }
`;

const StyledLoadingContent = styled.div`
  top: 40vh;
  left: 40%;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);

  img {
    z-index: 100;
    position: absolute;
    height: 250px;
    width: 250px;
  }
`;


export const FeatureFlagComponent = (props) => {
  const
    { cookiesDomain } = useEnvironment(),
    { featureFlags: flags,
      loading: loadingFeatureFlags,
      error: err,
      deployFeatureFlags,
      getFeatureFlags
    } = useFeatureFlags(),
    defaultFlags = getDefaultFeatureFlags(),
    [ flagLevel, setFlagLevel ] = useState('default'),
    [ flagStates, setFlagState ] = useState<FeatureFlags>(defaultFlags),
    [ activeFlags, setActiveFlags ] = useState<Record<string, boolean>>(
      Object.fromEntries(Object.keys(defaultFlags).map((f => [f, true]))));

  useEffect(() => {
    setFlagState(flags)
  }, [flags])

  const toggleChangeHandler = (flag, newVal) => {
    setFlagState((prev) => {

      return {
        ...prev,
        [flag]: newVal
      }

    });
  }

  const toggleActiveHandler = (flag: string) => {
    setActiveFlags((prev) => {
      return {
        ...prev,
        [flag]:!activeFlags[flag]
      }
    })
  }

  const ApplyBtnHandler = (event) => {
    const flagsToCookie = JSON.stringify(flagStates);


    if(flagsToCookie.length>0){
      document.cookie = `feature-flags=${flagsToCookie};`
      + `domain=${cookiesDomain};`
      + 'path=/;'
      + 'samesite=strict; secure;';

      if(confirm("Do you wish to refresh the browser to see changes?")){
        window.location.reload();
      }
    }
  }

  const DeployBtnHandler = (event) => {
    if(confirm("Are sure you wish to deploy the changes?")){

      const toDeploy = featureFlagsToDeploy(flagStates, Object.keys(activeFlags).filter(flag => activeFlags[flag]));

      if(['environment', 'override'].includes(flagLevel)){
        deployFeatureFlags(toDeploy, flagLevel);
      }
    }
  }

  const featureFlagLevelChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const val = e.target.value;

    setFlagLevel(val);
    getFeatureFlags(val);
  }

  return (
    <StyledFFContainer>
      <h2>Feature flags from the&nbsp;
      <select
        id='select-feature-flag-level'
        defaultValue={'default'}
        className={loadingFeatureFlags ? 'disabled-input' : ''}
        disabled={loadingFeatureFlags}
        onChange={featureFlagLevelChangeHandler}
        >
        <option value={'default'} key={'default'}>{'Default'}</option>
        <option value={'environment'} key={'environment'}>{'Environment'}</option>
        <option value={'override'} key={'override'}>{'Override'}</option>
        <option value={'session'} key={'session'}>{'Session'}</option>
      </select> Level</h2>
      <ul>
        <li>Hide options that you don't want deployed.</li>
      </ul>
      { loadingFeatureFlags &&
        <StyledLoadingContent>
          <img src='../assets/loading_icon.gif'/>
        </StyledLoadingContent>
      }
      { !loadingFeatureFlags && !err && <form>
        { Object.keys(flagStates).sort((a,b) => a.localeCompare(b)).map(
          (f, idx) =>{
          const
            isActive = activeFlags[f],
            isChecked = !flagStates[f] ? false : flagStates[f];

          return (
            <StyledSwitchContainer key={f}>
              <svg xmlns="http://www.w3.org/2000/svg"
                className={ isActive ? 'active': 'inactive'}
                width="24" height="24" viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => toggleActiveHandler(f)}
                >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                <path d="M12 15l-4 -4h8z"></path>
              </svg>
              <div className={ isActive? 'active' : 'inactive' }>
                <StyledSwitch className={flagLevel === 'default' ? 'toggle-switch disabled-input' : 'toggle-switch'}>
                  <input key={idx}
                    type='checkbox'
                    checked={isChecked}
                    disabled={flagLevel === 'default'}
                    onChange={() => toggleChangeHandler(f, !isChecked)}
                  />
                  <div className='slider round'></div>
                </StyledSwitch>
                {f}
              </div>
            </StyledSwitchContainer>
          );
        })
        }
        <StyledButtons>
          { flagLevel === 'session' &&
            <Button onClick={ApplyBtnHandler}>Apply</Button>
          }
          { ['environment', 'override'].includes(flagLevel) &&
            <Button onClick={DeployBtnHandler}>Deploy</Button>
          }
        </StyledButtons>
        </form>
      }
      { !loadingFeatureFlags && err && <h2>An error occured while retrieving the feature flags</h2>}
    </StyledFFContainer>
  )
}

export default FeatureFlagComponent;
