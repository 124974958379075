import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useFeatureFlags } from '@lawcpd/feature-flags/provider';
import { competencyTypeFromString, IoCourse, isRelativeUrl, MCompetencyType } from '@lawcpd/learner/shared/data';
import { theme } from '../theme';
import DefaultThumbnail from './default.png';

const
  lhPoints = "1.6rem",
  sizePoints = "1.8rem";

const StyledCourse = styled.header`
  div {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  div span {
    margin: 0 0.4rem 0 0;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0 0 0 8.1rem;
    padding: 0;

    a {
      color: ${theme.font};
      text-decoration: none;
    }
  }

  img {
    float: left;
    margin: 0 0.6rem 0 0;
  }

  @media only screen and ${theme.notLargeDevice} {
    img {
      margin-top: 0.6rem;
    }
  }

  .competency {
    color: ${theme.orange};
  }

  .cpd-points {
    display: inline-block;
  }

  .points {
    background: ${theme.orange};
    border-radius: 50%;
    color: white;
    display: inline-block;
    font-weight: 500;
    height: ${sizePoints};
    line-height: ${lhPoints};
    text-align: center;
    width: ${sizePoints};
  }
`;

export const Course = (props: IoCourse & { showLinks?: boolean }) => {
  const
    { error, featureFlags: { COMPLETION_SUMMARY_CASE_9072 }, loading } = useFeatureFlags(),
    isLargeDevice = theme.isLargeDevice(),
    cpdPoints = props.data.cpdPoints,
    //cpdPoints = props.cpdPoints === 0.5 ? String.fromCharCode(189) : props.cpdPoints,
    title = props.data.summary ? props.data.summary.replace(/(<([^>]+)>)/ig, '') : '',
    showLinks = ('showLinks' in props) && props.showLinks !== null ? props.showLinks : true,
    isRelative = isRelativeUrl(props.data.url),
    img = <img height="42" src={props.data.thumbnailUrl ? props.data.thumbnailUrl : DefaultThumbnail} title={title} width="75"></img>,
    competencyType = props.data.competencyType;

  let competency;
  switch (competencyType.length) {
    case 1: competency = MCompetencyType[competencyTypeFromString(competencyType[0])]; break;
    case 4: competency = props.data.competency || 'Cover All Competencies'; break;
    default: competency = props.data.competency || 'Unknown'; break;
  }

  // At this point it's better to check if the data url itself is a relative path or an absolute path due to how many ternary checks
  // are needed to determine if it would use Link or anchor tag; this is to reduce cognitive complexity.
  return (
    <StyledCourse>
      {loading && <div>Loading...</div>}
      {error && <div>Error...</div>}
      {!loading && !error && <>
        {isLargeDevice && showLinks && <>
            {isRelative && <Link to={props.data.url}>{img}</Link>}
            {!isRelative && <a href={props.data.url}>{img}</a>}
        </>}
        {isLargeDevice && !showLinks && img}
        <div>
          <span className="competency">{competency}</span>
          <span>|</span>
          <span className="cpd-points">CPD Points</span>
          <div className="points">{cpdPoints}</div>
        </div>
        {!isLargeDevice && img}
        <h1>
          {showLinks && <>
            {isRelative && <Link to={props.data.url}>{props.data.courseName}</Link>}
            {!isRelative && <a href={props.data.url}>{props.data.courseName}</a>}
          </>}
          {!showLinks && props.data.courseName}
        </h1>
      </>}
    </StyledCourse>
  );
};

export default Course;
