import React, { useContext } from 'react';
import { IExternalCpdContext } from '@lawcpd/learner/shared/data';

export const ExternalCpdContext = React.createContext<IExternalCpdContext | null>(null);
export const useExternalCpdRecords = (flag: boolean = false) => {
  if(flag){
    return useContext(ExternalCpdContext)
  }
  else{
    return useContext(React.createContext<IExternalCpdContext>({
      records: [],
      loading: false,
      error: false,
      deleteRecord: () => null
    }));
  }
};
