import styled from 'styled-components';

/* eslint-disable-next-line */
export interface SupportProviderProps {}

const StyledSupportProvider = styled.div`
  color: pink;
`;

export function SupportProvider(props: SupportProviderProps) {
  return (
    <StyledSupportProvider>
      <h1>Welcome to SupportProvider!</h1>
    </StyledSupportProvider>
  );
}

export default SupportProvider;
