import styled from 'styled-components';

/* eslint-disable-next-line */
export interface FirebaseProps {}

const StyledFirebase = styled.div`
  color: pink;
`;

export function Firebase(props: FirebaseProps) {
  return (
    <StyledFirebase>
      <h1>Welcome to Firebase!</h1>
    </StyledFirebase>
  );
}

export default Firebase;
