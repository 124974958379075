import { AnnotatedAllocation, IAllocation, ECompetencyType, ICourse, getCpdYear, orderCompetencyType, IExternalCpd } from '@lawcpd/learner/shared/data';

export function completed(allocation: IAllocation): boolean {
  return allocation.data.completed ? true : false;
}

export function filterAllocatedFrom(date: Date): (value: IAllocation, index: number, array: IAllocation[]) => boolean {
  return (allocation) => allocation.metadata.created > date;
}

export function filterCompletedInCpdYear(cpdYear: number): (value: IAllocation, index?: number, array?: IAllocation[]) => boolean {
  return (allocation) => completed(allocation) && (!cpdYear || getCpdYear(allocation.data.completed) === cpdYear);
}

export function filterCompletedInCpdYears(cpdYears: number[]): (value: IAllocation, index?: number, array?: IAllocation[]) => boolean {
  return (allocation) => completed(allocation) && (cpdYears.includes(getCpdYear(allocation.data.completed)));
}

export function filterInCpdYear(cpdYear: number): (value: IAllocation, index: number, array: IAllocation[]) => boolean {
  return (allocation) => notCompleted(allocation) || (getCpdYear(allocation.data.completed) === cpdYear);
}

export function filterInCpdYears(cpdYears: number[]): (value: IAllocation, index: number, array: IAllocation[]) => boolean {
  return (allocation) => notCompleted(allocation) || (cpdYears.includes(getCpdYear(allocation.data.completed)));
}

export function getCompetencies(allocations: AnnotatedAllocation[], externalRecords: IExternalCpd[] = []): Set<ECompetencyType> {
  // Note: Original getCompetencies should not be dependent on External CPD...
  // Return a set of competencies for the passed in allocations.
  // Used for compliance, so total cpd needs to be 1 or greater.
  const allocationCompetencies = allocations.reduce((map, a) => {
    if (a.course) {
      a.course.data.competencyType.forEach(ct => {
        if (ct in  map) {
          map[ct] += a.course.data.cpdPoints;
        }
      });
    }
    return map;
  }, orderCompetencyType.reduce((map, ct) => {
    map[ct] = 0;
    return map;
  }, <Record<ECompetencyType, number>>{})),
  totalCompetencies = externalRecords.reduce((map, r) => {
    const competency = r.data.competency;
    if(competency) map[competency] += r.data.cpdPoints;
    return map;
  }, allocationCompetencies),
  fulfilledCompetencies = new Set(Object.entries(totalCompetencies).filter(([, cpd]) => cpd >= 1).map(([ct,]) => parseInt(ct)));

  return fulfilledCompetencies;
}

export function getCompetencies2(courses: ICourse[]): Set<ECompetencyType> {
  // Return a set of competencies for the passed in courses.
  return new Set([].concat.apply([], courses.map(course => course.data.competencyType)));
}

export function getCpdPoints(allocations: AnnotatedAllocation[]): number {
  return allocations.reduce((sum, allocation) => sum + ('course' in allocation ? allocation.course.data.cpdPoints : 0), 0);
}

export function getCpdYears(allocations: IAllocation[]): number[] {
  // Return an array of years for allocations, including current year if anything in progress or not started.
  const
    cpdYear = getCpdYear(new Date()),
    cpdYears = new Set<number>();
  for (let i = 0; i < allocations.length; i++) {
    if (allocations[i].data.completed) {
      cpdYears.add(getCpdYear(allocations[i].data.completed));
    } else {
      cpdYears.add(cpdYear);
    }
  }
  return Array.from(cpdYears);
}

export function inProgressThenMostRecentAccessThenMostRecentAllocation(allocationA: IAllocation, allocationB: IAllocation): -1|0|1 {
  let ret: -1|0|1 = 1;
  if (notStarted(allocationA) || completed(allocationA)) {
    if (notStarted(allocationB) || completed(allocationB)) {
      ret = mostRecentAccessThenMostRecentAllocation(allocationA, allocationB);
    }
  } else { // A in progress
    if (notStarted(allocationB) || completed(allocationB)) {
      ret = -1;
    } else {
      ret = mostRecentAccessThenMostRecentAllocation(allocationA, allocationB);
    }
  }
  return ret;
}

export function isLaunchable(allocation: AnnotatedAllocation): boolean {
  return allocation.course && allocation.course.data.isLaunchable ? true : false;
}

export function mostRecentAccess(allocationA: IAllocation, allocationB: IAllocation): -1|0|1 {
  let ret: -1|0|1 = -1;
  const
    updatedA = completed(allocationA) ? allocationA.data.completed : allocationA.metadata.updated,
    updatedB = completed(allocationB) ? allocationB.data.completed : allocationB.metadata.updated;

  if (updatedA < updatedB) {
    ret = 1;
  } else if (updatedA === updatedB) {
    ret = 0;
  }
  return ret;
}

export function mostRecentAccessThenMostRecentAllocation(allocationA: IAllocation, allocationB: IAllocation): -1|0|1 {
  let ret: -1|0|1 = mostRecentAccess(allocationA, allocationB);

  if (ret === 0) {
    if (allocationA.metadata.created < allocationB.metadata.created) {
      ret = 1;
    } else if (allocationA.metadata.created > allocationB.metadata.created) {
      ret = -1;
    }
  }
  return ret;
}

export function notCompleted(allocation: IAllocation): boolean {
  return !completed(allocation);
}

export function notStarted(allocation: IAllocation): boolean {
  return !started(allocation);
}

export function started(allocation: IAllocation): boolean {
  return allocation.data.started ? true : false;
}

export function inProgress(allocation: IAllocation): boolean {
  return started(allocation) && notCompleted(allocation);
}

export const mapValuesBasedOnKeys = (arr: object[], key: string, val: string ): Record<string, any[]> =>{
  // Only ever called with sortValuesBasedOnKeys(searchTerms, 'criteria', 'value').
  // searchTerms is SearchString[], Searchstring is object only with criteria and value properties.
  // So, takes an array of { criteria, value } and returns an object with criteria properties and an array of values values.
  // ie [{ criteria: 'a', value: '1'}, { criteria: 'a', value: '2'}, { criteria: 'b', value: '3' }] => { a: ['1', '2'], b: ['3'] }
  const groupedItems = {};

  for(const i of arr){
    const arrKey = i[key];
    groupedItems[arrKey] = groupedItems[arrKey] ? [...groupedItems[arrKey], i[val]] : [i[val]];
  }

  return groupedItems;
}

export const joinValuesIntoQueryForm = (toQuery: Record<string, any[]>): string[] => {
  // Only ever called with result of above sortValuesBasedOnKeys.
  // ie { a: ['1', '2'], b: ['3'] } => ['a::1++2', 'b::3']

  const queryForm = []
  for(const [k, v] of Object.entries(toQuery)){
    queryForm.push(`${k}::${v.join('++')}`);
  }

  return queryForm;
}
