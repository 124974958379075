import styled from 'styled-components';

/* eslint-disable-next-line */
export interface EventSupportLogsProps {}

const StyledEventSupportLogs = styled.div`
  color: pink;
`;

export function EventSupportLogs(props: EventSupportLogsProps) {
  return (
    <StyledEventSupportLogs>
      <h1>Welcome to EventSupportLogs!</h1>
    </StyledEventSupportLogs>
  );
}

export default EventSupportLogs;
