import styled from 'styled-components';
import { IAllocationsProps } from '@lawcpd/learner/shared/data';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { Allocation } from '../allocation/allocation';
import { Button, Secondary } from '../button/button';
import { theme } from '../theme';

const StyledAllocations = styled.table`
  border-spacing: 0;
  margin: 2rem 0;
  padding: 0;
  width: 100%;
`;

export const Allocations = (props: IAllocationsProps) => {
  const
    { magento: { legalcpd } } = useEnvironment(),
    { COMPLETION_SUMMARY_CASE_9072, REVIEWABLE_CASE_10297, USE_COURSE_IFRAMES_CASE_9404 } = props,
    hasResults = props.allocations.length ? true : false,
    message = ('message' in props) && props.message !== null ? props.message : 'Need to choose more CPD courses? Get started now',
    showCTA = 'showCTA' in props ? props.showCTA : true,
    urlCTA = 'urlCTA' in props ? props.urlCTA : legalcpd;

  return (
    <>
      {!hasResults && <>
        <p>{message}</p>
        {showCTA && <Button href={urlCTA} maxWidth={theme.buttonWidthDouble} target="_blank" type={Secondary}>{theme.textChooseMyNextCourse}</Button>}
      </>}
      {hasResults && <StyledAllocations >
        <tbody>
          {props.allocations.map(allocation => (
            <Allocation
              key={allocation.id}
              allocation={allocation}
              show={props.show}
              COMPLETION_SUMMARY_CASE_9072={COMPLETION_SUMMARY_CASE_9072}
              REVIEWABLE_CASE_10297={REVIEWABLE_CASE_10297}
              USE_COURSE_IFRAMES_CASE_9404={USE_COURSE_IFRAMES_CASE_9404}
            />
          ))}
        </tbody>
      </StyledAllocations>}
    </>
  );
};

export default Allocations;
