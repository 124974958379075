import React, { useState } from "react";
import { EnvironmentContext } from "./environment";
import { IEnvironmentContext } from '@lawcpd/learner/shared/data';

interface IEnvironmentProviderOptions {
  children: any; // Should be React.ReactElement, but Auth0Provider is not matching.
  environment: IEnvironmentContext;
}

export const EnvironmentProvider = ({
  children,
  environment,
}: IEnvironmentProviderOptions) => {
  const
    [auth0] = useState(environment.auth0),
    [firebase] = useState(environment.firebase),
    [course] = useState(environment.course),
    [development] = useState(environment.development),
    [assets] = useState(environment.assets),
    [cookiesDomain] = useState(environment.development ? 'localhost': environment.domain),
    [domain] = useState(environment.domain),
    [google] = useState(environment.google),
    [help] = useState(environment.help),
    [login] = useState(environment.login),
    [magento] = useState(environment.magento),
    [mycpd] = useState(environment.mycpd),
    [support] = useState(environment.support),
    [production] = useState(environment.production),
    [featureFlag] = useState(environment.featureFlag);

  return (
    <EnvironmentContext.Provider
      value={{
        auth0,
        firebase,
        course,
        development,
        assets,
        cookiesDomain,
        domain,
        google,
        help,
        login,
        magento,
        mycpd,
        support,
        production,
        featureFlag
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
