import { JWT } from './learner-shared-data';

export const user: JWT = {
  'https://feature.lawcpd.com.au/flags': {
      'USE_COURSE_IFRAMES_CASE_9404': true,
      'USE_MAILCHIMP_CASE_5763': true,
      'SPLIT_OUT_THIRD_PARTY_API_10125': true,
  },
  'https://lawcpd.com.au/id': 'c36af0c5f00238737453e2e8627eb4f11a2b36db0e93cb5cb27487e9e056285accba10763ffa7d113043ea338b8bd48b3f9286f97758e11d6dca1853fe322958',
  'https://lawcpd.com.au/cid': '3608',
  'https://lawcpd.com.au/groups': ['Developer', 'LawCPD'],
  'https://lawcpd.com.au/roles': ['Support'],
  'https://lawcpd.com.au/email': "administration@lawcpd.com.au",
  'sub': 'auth0|drupal-13922',
};
