import { ILearnerContext } from '@lawcpd/learner/shared/data';
import { Meta } from '@storybook/react';
import { LearnerContext } from './learner';

// Learner Provider Decorator
export const StorybookLearnerProvider = (Story, context) => {
  const {
    email, familyName, givenName,
    organisation, discipline, jurisdiction,
    learnerAuth0Id, learnerId, magentoId, practitionerId,
    learnerError, learnerLoading
  } = context.args;

  return (
    <LearnerContext.Provider
      value={{
        discipline,
        email,
        error: learnerError,
        familyName,
        givenName,
        id: learnerAuth0Id,
        jurisdiction,
        learnerId,
        loading: learnerLoading,
        magentoId,
        organisation,
        practitionerId,
      }}
    >
      <Story />
    </LearnerContext.Provider>
  );
};

type LearnerArgTypes = {
  name: string;
  control: 'number' | 'text' | 'boolean',
  table: {
    category: "Learner";
    subcategory?: string;
  }
};

export interface IStoryLearnerContext {
  discipline: string,
  email: string,
  familyName: string,
  givenName: string,
  learnerAuth0Id: string,
  jurisdiction: string,
  learnerId: string,
  magentoId: number,
  organisation: string,
  practitionerId: string,
  learnerError : boolean,
  learnerLoading :  boolean
}

export const StoryBookLearnerArgs: IStoryLearnerContext = {
  discipline: "Discipline",
  email: "roger.rabbit@example.com",
  familyName: "Rabbit",
  givenName: "Roger",
  learnerAuth0Id: "auth0|tba",
  jurisdiction: "Western Australia",
  learnerId: "lawcpd-1-99999",
  magentoId: 99999,
  organisation: "Warner Brothers",
  practitionerId: "",
  learnerError : false,
  learnerLoading :  true
};

export const StoryBookLearnerArgTypes: Record<keyof IStoryLearnerContext, LearnerArgTypes> = {
  discipline: {
    name: "Discipline",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  email: {
    name: "Email",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  familyName: {
    name: "Family Name",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  givenName: {
    name: "Given Name",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  learnerAuth0Id: {
    name: "Id",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  jurisdiction: {
    name: "Jurisdiction",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  learnerId: {
    name: "Learner Id",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  magentoId: {
    name: "Magento Id",
    control: "number",
    table: {
      category: "Learner",
    }
  },
  organisation: {
    name: "Organisation",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  practitionerId: {
    name: "Practitioner Id",
    control: "text",
    table: {
      category: "Learner",
    }
  },
  learnerLoading: {
    name: "Loading?",
    control: "boolean",
    table: {
      category: "Learner",
      subcategory: "States"
    }
  },
  learnerError : {
    name: "Error?",
    control: "boolean",
    table: {
      category: "Learner",
      subcategory: "States"
    }
  }
};

export default StorybookLearnerProvider;
