import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../theme';

/* eslint-disable-next-line */
export interface ComplianceCompetencyProps {
  ethics?: boolean;
  practiceManagement?: boolean;
  professionalSkills?: boolean;
  showLinks?: boolean;
  substantiveLaw?: boolean;
}

const StyledComplianceCompetency = styled.div`
  color: #3f3f3c;
  font-size: 1.4rem;
  line-height: 1.6rem;
  min-width: 16.8rem;
  width: 100%;

  strong {
    padding: 0.5rem 0;
  }

  ul {
    margin: 0;
    padding: 0.5rem 0 0;
    text-transform: uppercase;

    li {
      align-items: center;
      color: ${theme.orange};
      display: flex;
      justify-content: space-between;
      padding: 0.3rem 0;

      a {
        color: ${theme.orange};
        text-decoration: none;
      }
    }
  }
`;

function Competency(props) {
  return props.withLink ? <NavLink to={props.href}>{props.title}</NavLink> : props.title;
}

function Tick(props) {
  const description = props.complete
    ? `A circle with a tick representing completed courses in the ${props.title} competency.`
    : `A circle representing no completed courses in the ${props.title} competency.`;

  return (
    <svg xmlns="https://www.w3.org/2000/svg" version="1.1" focusable="false" height="21" role="img" width="21" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
      <title>{props.title}</title>
      <desc>{description}</desc>
      <circle cx="10.5" cy="10.5" fill="transparent" r="8.5" strokeDasharray="53.407" strokeDashoffset="0" strokeWidth="2" transform="rotate(-89.9 10.5 10.5)" style={{ stroke: theme.orange, transition: 'stroke-dashoffset 0.4s ease-out 0s, fill 0.3s ease 0.3s', fill: theme.orange }}></circle>
      {props.complete && <path d="M8.64,0L9.9,1.17L3.51,7.2L0,3.87L1.26,2.7l2.34,2.16L8.64,0z" fill="#fff" transform="translate(5.5 7)" style={{ transition: 'opacity 0.3s ease 0.3s' }}></path>}
    </svg>);
}

export const ComplianceCompetency = (props: ComplianceCompetencyProps) => {
  const
    competency = Object.assign({
      ethics: false,
      practiceManagement: false,
      professionalSkills: false,
      showLinks: true,
      substantiveLaw: false,
    }, props);

  return (
    <StyledComplianceCompetency className="compliance-competency">
      <strong>Compliance by core competency</strong>
      <ul>
        <li><Competency title="Ethics" href="/cpd/ethics" withLink={competency.showLinks} /><Tick title="Ethics" complete={competency.ethics} /></li>
        <li><Competency title="Practice Management" href="/cpd/practice-management" withLink={competency.showLinks} /><Tick title="Practice Management" complete={competency.practiceManagement} /></li>
        <li><Competency title="Professional Skills" href="/cpd/professional-skills" withLink={competency.showLinks} /><Tick title="Professional Skills" complete={competency.professionalSkills} /></li>
        <li><Competency title="Substantive Law" href="/cpd/substantive-law" withLink={competency.showLinks} /><Tick title="Substantive Law" complete={competency.substantiveLaw} /></li>
      </ul>
    </StyledComplianceCompetency>
  );
};

export default ComplianceCompetency;
