
import {
  Auth, signOut, User,
  createUserWithEmailAndPassword,
  RecaptchaVerifier, sendEmailVerification,
  signInWithCustomToken, signInWithEmailAndPassword,
} from 'firebase/auth';
import { MFASignIn, signInWithMFA } from './multi-factor';

export class UnverifiedEmailError extends Error {
  constructor(message: string){
    super(message);
    this.name = 'UnverifiedEmailError';
  }
}
export class NoMFAsEnrolledError extends Error {
  constructor(message: string){
    super(message);
    this.name = 'NoMFAsEnrolledError';
  }
}

export function createNewUser(authApp: Auth, email: string, password: string){

  return createUserWithEmailAndPassword(authApp, email, 'test123')
  .then((userCredential) =>{
    const user = userCredential.user;

    sendEmailVerification(user);
  })
  .catch((error) =>{
    const
      errCode = error.code,
      errMessage = error.message;
    console.error('Error while creating a new user: ', errMessage);
    alert('An error occured while creating a new user');
  })
}

// Use for JWT
export function loginWithCustomToken(authApp: Auth, token: string){
  return signInWithCustomToken(authApp, token)
  .then((userCredential) => {
    const user = userCredential.user;

    return user;
  })
  .catch((error) => {
    const
      errCode = error.code,
      errMessage = error.message;
    if(errCode === 'auth/id-token-expired'){
      return Promise.reject(new Error('Token has expired.'))
    }
    else{
      console.log('Error occured while logging in with custom token: ', errMessage)
      return Promise.reject(error);
    }
  })
}

export function loginWithEmailAndPassword(
  authApp: Auth, email: string,
  password: string, recaptcha: RecaptchaVerifier
  ):Promise<User | MFASignIn>{
  return signInWithEmailAndPassword(authApp, email, password)
  .then((userCredential) => {
    const user = userCredential.user;

    if(!user.emailVerified) {
      sendEmailVerification(user);
      throw new UnverifiedEmailError('Please verify your email, an email has been sent to you.');
    };

    return user;
  })
  .catch((error) =>{
    const
      errCode = error.code,
      errMessage = error.message;

    if(errCode === 'auth/multi-factor-auth-required'){
      return signInWithMFA(authApp, error, 0, recaptcha);
    }
    else if(errCode === 'auth/invalid-login-credentials' || errCode === 'auth/internal-error'){
      return Promise.reject(new Error('Erroneous credentials provided'));
    }
    else if(error.name === 'UnverifiedEmailError'){
      return Promise.reject(error);
    }
    else{
      console.error('Error occured while logging in:', errCode);
      return Promise.reject(new Error('An error occured while logging in, please try again'));
    }
  })
}

export const signOutFromSystem  = (authApp: Auth) => {
  return signOut(authApp);
}
