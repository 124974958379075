import React, { useEffect, useState } from 'react';
import { ICourse, IoCourse, courseFromIo } from '@lawcpd/learner/shared/data';
import { CoursesContext } from './courses';
import { useAuth0 } from '../auth0/auth0';
import { useEnvironment } from '../environment/environment';
import { useLearner } from '../learner/learner';
import { useFirebaseAuth } from 'support-provider';

interface ICoursesProviderOptions {
  children: React.ReactElement | React.ReactElement[];
}

export const CoursesProvider = ({
  children,
}: ICoursesProviderOptions) => {
  const
    { getIdTokenClaims } = useAuth0(),
    { mycpd: { api } } = useEnvironment(),
    { id } = useLearner(),
    [courses, setCourses] = useState<Record<string, ICourse>>({}),
    [error, setError] = useState(false),
    [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const apiPath = api + '/api/course?isPurchasable=true';
      getIdTokenClaims()
        .then(idToken => fetch(apiPath, { headers: { Authorization: `Bearer ${idToken.__raw}` } }))
        .then(_ => _.json())
        .then(response => {
          if ('error' in response) {
            setError(true);
          } else {
            const map = Object.values(response[0]).reduce((map, course: IoCourse) => {
              map[course.id] = courseFromIo(course);
              return map;
            }, {}) as Record<string, ICourse>;
            setCourses(map);
          }
          setLoading(false);
        })
        .catch(e => {
          setError(true);
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <CoursesContext.Provider
      value={{
        courses: courses,
        error: error,
        loading: loading,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};

export const CoursesProviderFirebase = ({
  children,
}: ICoursesProviderOptions) => {
  const
    { user } = useFirebaseAuth(),
    { mycpd: { api } } = useEnvironment(),
    [courses, setCourses] = useState<Record<string, ICourse>>({}),
    [error, setError] = useState(false),
    [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const apiPath = api + '/api/course?all=true';
      user.getIdToken()
        .then(idToken => fetch(apiPath, { headers: { Authorization: `Bearer ${idToken}` } }))
        .then(_ => _.json())
        .then(response => {
          if ('error' in response) {
            setError(true);
          } else {
            const map = Object.values(response[0]).reduce((map, course: IoCourse) => {
              map[course.id] = courseFromIo(course);
              return map;
            }, {}) as Record<string, ICourse>;
            setCourses(map);
          }
          setLoading(false);
        })
        .catch(e => {
          setError(true);
          setLoading(false);
        });
    }
  }, [user]);

  return (
    <CoursesContext.Provider
      value={{
        courses: courses,
        error: error,
        loading: loading,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};

export default CoursesProvider;
