import React from 'react';
import { AnnotatedAllocation, IAllocation, IAllocationShow } from '@lawcpd/learner/shared/data';
import { Allocations } from "@lawcpd/learner/shared/ui";

/* eslint-disable-next-line */
export interface AllocationsFilterProps {
  select?: IAllocationShow;
  from: AnnotatedAllocation[];
  limit?: number;
  orderBy?: (allocationA: IAllocation, allocationB: IAllocation) => number;
  where?: (allocation: IAllocation) => boolean;
  message?: string | React.ReactElement;
  showCTA?: boolean;
  urlCTA?: string;
  COMPLETION_SUMMARY_CASE_9072: boolean;
  REVIEWABLE_CASE_10297: boolean;
  USE_COURSE_IFRAMES_CASE_9404: boolean;
};

export const AllocationsFilter = (props: AllocationsFilterProps) => {
  const
    { COMPLETION_SUMMARY_CASE_9072, REVIEWABLE_CASE_10297, USE_COURSE_IFRAMES_CASE_9404 } = props,
    subprops = { show: props.select };
  ['message', 'showCTA', 'urlCTA'].forEach(p => {
    if ((p in props) && props[p] !== null) {
      subprops[p] = props[p];
    }
  });

  let allocations = props.from;
  if ("where" in props) {
    allocations = allocations.filter(props.where);
  }
  if ("orderBy" in props) {
    allocations.sort(props.orderBy);
  }
  if ("limit" in props) {
    allocations = allocations.slice(0, props.limit);
  }
  return (
    <Allocations
      allocations={allocations}
      COMPLETION_SUMMARY_CASE_9072={COMPLETION_SUMMARY_CASE_9072}
      REVIEWABLE_CASE_10297={REVIEWABLE_CASE_10297}
      USE_COURSE_IFRAMES_CASE_9404={USE_COURSE_IFRAMES_CASE_9404}
      {...subprops}
    />
  );
};

export default AllocationsFilter;
