import Cookies from 'js-cookie';
import React, { useEffect, useState, useMemo } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@lawcpd/learner/shared/provider';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';

export const PrivateRoute = (props) => {
  const
    location = useLocation(),
    [ toRender, setToRender ] = useState<'children'|'redirect'|null>(),
    { login: { login } } = useEnvironment(),
    { getTokenSilently, isAuthenticated, loading, loginWithRedirect } = useAuth0(),
    { children } = props;

    useEffect(() => {
    if(loading) { setToRender(null); }
    else if(isAuthenticated) { setToRender('children'); }
    else {
      const fn = async () => {
        try {
          await getTokenSilently();
          await loginWithRedirect({
            appState: { targetUrl: Cookies.get('before_auth_url') || location.pathname }
          });

          document.cookie = 'before_auth_url=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; samesite=strict; secure;';
          setToRender('children');
        }
        catch(error) {
          if(error.error !== 'login_required') { throw error; }
          else {
            document.cookie = `before_auth_url=${location.pathname}; path=/; samesite=strict; secure;`;
            setToRender('redirect');
          }
        }
      }
      fn();
    }
  }, [loading, isAuthenticated]);

  switch(toRender){
    case 'children': return children;
    case 'redirect' : window.location.assign(login);
    default: return null;
  }
}

export const PrivateRouteAdmin = ({children, ...rest}) => {
  const
    [ toRender, setToRender ] = useState<'children'|'redirect'|null>(),
    { cookiesDomain, support: { login } } = useEnvironment(),
    { firebaseApp, isAuthenticated, loading, getCustomToken, logInWithJWT } = useFirebaseAuth();

  useEffect(() => {
    if(loading || !firebaseApp) { setToRender(null); }
    else if(isAuthenticated) { setToRender('children'); }
    else {
      const fn = async () => {
        try{
          const token = Cookies.get('_fbt');
          if(!token) { throw new Error('No token found'); }

          // Login with initial firebase token from login page
          // upon redirect request a custom token to login in support
          // upon logging in with custom token use id token to authenticate all api calls
          const customToken = await getCustomToken(token);
          const fbt = await logInWithJWT(customToken);
          document.cookie = `fbt=${fbt};`
          + `domain=${cookiesDomain};`
          + 'path=/;'
          + 'samesite=strict; secure;';
          document.cookie = 'before_auth_url=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; samesite=strict; secure;';
          setToRender('children');
        }
        catch(error){
          console.error(error.message)
          document.cookie = `before_auth_url=${window.location.href}; path=/; samesite=strict; secure;`;
          setToRender('redirect'); // new login url for admin login page
        }
      };
      fn();
    }
  }, [loading, isAuthenticated, firebaseApp]);

  switch(toRender){
    case 'children': return children;
    case 'redirect' : window.location.assign(login);
    default: return null;
  }
}
