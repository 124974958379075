import React from 'react';

import styled from 'styled-components';

/* eslint-disable-next-line */
export interface LearnerSharedFilterProps {}

const StyledLearnerSharedFilter = styled.div`
  color: pink;
`;

export const LearnerSharedFilter = (props: LearnerSharedFilterProps) => {
  return (
    <StyledLearnerSharedFilter>
      <h1>Welcome to learner-shared-filter component!</h1>
    </StyledLearnerSharedFilter>
  );
};

export default LearnerSharedFilter;
