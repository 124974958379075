import React, { useEffect, useState } from 'react';
import { ExternalCpdContext } from './external-cpd';
import { useAuth0 } from '../auth0/auth0';
import { useEnvironment } from '../environment/environment';
import { useLearner } from '../learner/learner';
import { externalCpdFromIo, IExternalCpd } from '@lawcpd/learner/shared/data';

interface IExternalCpdRecordsProviderOptions {
  children: React.ReactElement;
}

export const ExternalCpdRecordsProvider = ({
  children,
}: IExternalCpdRecordsProviderOptions) => {
  const
    { getIdTokenClaims } = useAuth0(),
    { mycpd: { api } } = useEnvironment(),
    { learnerId } = useLearner(),
    [externalCpds, setExternalCpds] = useState<IExternalCpd[]>([]),
    [error, setError] = useState(false),
    [loading, setLoading] = useState(true);

  useEffect(() => {
    if (learnerId) {
      getIdTokenClaims()
      .then(token => readExternalCpd(token.__raw));
    }
  }, [learnerId]);

  function readExternalCpd(token: string){
    const apiPath = `${api}/api/learner/${encodeURI(learnerId)}/external-cpd`;
    setLoading(true);

    fetch(apiPath, { headers: { Authorization: `Bearer ${token}` } })
      .then(_ => _.json())
      .then(response => {
        if ('error' in response) {
          setError(true);
        } else {
          setExternalCpds(response[learnerId] && response[learnerId].length ? response[learnerId].map(externalCpdFromIo) : []);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  }

  function deleteExternalCpd(recordId: string){
    const isConfirmed = confirm("Are you sure you want to delete the record?");
    if(learnerId && isConfirmed){
      getIdTokenClaims()
      .then(async (idToken) => {
        await fetch(`${api}/api/external-cpd/${recordId}`, {
          headers : { Authorization: `Bearer ${idToken.__raw}`},
          method: 'DELETE'
        });
        await readExternalCpd(idToken.__raw)
      })
      .catch(e => {
        console.log(e);
        alert("Something went wrong with the deletion process.")
        setLoading(false);
      });
    }
  }

  return (
    <ExternalCpdContext.Provider
      value={{
        records: externalCpds,
        error: error,
        loading: loading,
        deleteRecord: deleteExternalCpd
      }}
    >
      {children}
    </ExternalCpdContext.Provider>
  );
};

export default ExternalCpdRecordsProvider;
