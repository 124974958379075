import React from 'react';

import styled from 'styled-components';

/* eslint-disable-next-line */
export interface LearnerSharedProviderProps {}

const StyledLearnerSharedProvider = styled.div`
  color: pink;
`;

export const LearnerSharedProvider = (props: LearnerSharedProviderProps) => {
  return (
    <StyledLearnerSharedProvider>
      <h1>Welcome to learner-shared-provider component!</h1>
    </StyledLearnerSharedProvider>
  );
};

export default LearnerSharedProvider;
