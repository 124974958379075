import styled from 'styled-components';
import { IExternalCpdRecordsProps } from '@lawcpd/learner/shared/data';
import { ExternalCpdRecord } from '../external-cpd/external-cpd';
import { Button, Tertiary } from '../button/button';
import { theme } from '../theme';

const StyledExternalCPD = styled.table`
  border-spacing: 0;
  margin: 2rem 0;
  padding: 0;
  width: 100%;
`;

export const ExternalCpdRecords = (props: IExternalCpdRecordsProps) => {
  const
    { records, launchPopup, show, DISPLAY_EXTERNAL_CPD_11361 } = props,
    hasResults = records.length ? true : false;

  return (
    <>
      {!hasResults &&
        <>
        {launchPopup &&
          <p>
            <span className="highlight">New feature!</span>{" "}
            You can now update the MyCPD Compliance Tracker with activities outside of LawCPD!{" "}
            <a href="#" onClick={launchPopup}>Click here</a> or on the button below to get started.
          </p>
        }
        {!launchPopup &&
          <p>No records</p>
        }
        </>
      }
      {hasResults &&
        <StyledExternalCPD>
          <tbody>
            {records.map(record => (
              <ExternalCpdRecord
                key={record.id}
                record={record}
                show={show}
                DISPLAY_EXTERNAL_CPD_11361={DISPLAY_EXTERNAL_CPD_11361}
              />
            ))}
          </tbody>
        </StyledExternalCPD>
      }
      { launchPopup &&
        <Button
          maxWidth={theme.buttonWidthDouble}
          type={Tertiary}
          onClick={launchPopup}
          >
          Add an External CPD Activity
        </Button>
      }
    </>
  );
};

export default ExternalCpdRecords;
