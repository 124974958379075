import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Backdrop } from '../modal/modal';
import { theme } from '../theme';

const StyledBackdropContent = styled.div`
  z-index: 101;
  top: 40vh;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
`

const StyledLoadingContent = styled.div`
  .lds-ring {
    display: flex;
    position: relative;
    height: 80px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 8px;
    border: 8px solid ${theme.orange};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme.orange} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface IBackDropSpinner {
  backdropId: string;
}

export const Spinner = () => {
  return (
    <StyledLoadingContent>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </StyledLoadingContent>
  )
}

export const BackdropSpinner = (props: IBackDropSpinner) => {
  if(!props.backdropId) return;

  return ReactDOM.createPortal(
    <Backdrop>
      <StyledBackdropContent>
        <Spinner />
      </StyledBackdropContent>
    </Backdrop>,
    document.getElementById(props.backdropId)
  )
}

export default Spinner;
