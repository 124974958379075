import { IExternalCpdShow, IExternalCpd } from '@lawcpd/learner/shared/data';
import { ExternalCpdRecords } from "@lawcpd/learner/shared/ui";
import { MouseEvent } from 'react';

/* eslint-disable-next-line */
export interface ExternalCpdRecordsFilterProps {
  launchPopup?: (e: MouseEvent) => void;
  select?: IExternalCpdShow;
  from: IExternalCpd[];
  limit?: number;
  orderBy?: (recordA: IExternalCpd, recordB: IExternalCpd) => number;
  where?: (record: IExternalCpd, index?:number, records?: IExternalCpd[]) => boolean;
  DISPLAY_EXTERNAL_CPD_11361: boolean;
};

export const ExternalCpdRecordsFilter = (props: ExternalCpdRecordsFilterProps) => {
  const subprops = { show: props.select };

  let records = props.from;
  if ("where" in props) {
    records = records.filter(props.where);
  }
  if ("orderBy" in props) {
    records.sort(props.orderBy);
  }
  if ("limit" in props) {
    records = records.slice(0, props.limit);
  }

  return (
    <ExternalCpdRecords
      records={records}
      launchPopup={props.launchPopup}
      DISPLAY_EXTERNAL_CPD_11361={props.DISPLAY_EXTERNAL_CPD_11361}
      {...subprops}
    />
  );
};

export default ExternalCpdRecordsFilter;
