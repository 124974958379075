import React, { useContext } from 'react';
import { ILearnerContext } from '@lawcpd/learner/shared/data';

export const LearnerContext = React.createContext<ILearnerContext | null>(null);
export const useLearner = (useLearner = true) => {
  // useLearner should only be false for front-end applications that are not dependent
  // in the learner itself, but some providers that are used are dependent on it. i.e. Support
  return useLearner ? useContext(LearnerContext)
  : useContext(React.createContext<ILearnerContext>({
    id: '',
    learnerId: '',
    magentoId: null,
    practitionerId: '',
    organisation: '',
    jurisdiction: '',
    discipline: '',
    email: '',
    familyName: '',
    givenName: '',
    featureFlags: {},
    error: false,
    loading: false,
  }));
};
