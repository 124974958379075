import styled from 'styled-components';
import { IAllocationProps, formatName, RemovedCourse, ioFromCourse } from '@lawcpd/learner/shared/data';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { Button, Secondary } from '../button/button';
import { Course } from '../course/course';
import { theme } from '../theme';

const StyledAllocation = styled.tr`
  font-size: 1.4rem;

  th, td {
    box-sizing: border-box;
    padding: ${theme.tableRow} ${theme.tableColumn};
    vertical-align: middle;
  }

  th {
    font-weight: normal;
    padding-left: 0;
    text-align: left;
  }

  td {
    min-width: ${theme.buttonWidth};
    text-align: center;
    white-space: nowrap;
    width: ${theme.buttonWidth};

    &:last-child {
      padding-right: 0;
    }
  }

  .allocation-author {
    text-align: left;
    width: auto;

    span {
      color: #999;
      text-transform: uppercase;
    }
  }

  .allocation-feedback {
    white-space: normal;

    a {
      color: ${theme.gold};
      text-decoration: none;

      div {
        color: ${theme.font};
        margin-top: -1rem;
      }

      svg {
        width: 2.1rem;
      }
    }
  }

  .complete {
    color: #f37021;
  }

  .expired, .progress {
    color: #999;
  }

  @media only screen and ${theme.notLargeDevice} {
    align-content: space-between;
    align-items: center;
    border: 1px solid #3f3f3c;
    border-radius: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding: 10px;
    margin-bottom: 10px;

    th {
      width: 100%;
    }

    td {
      padding: 0 ${theme.tableColumn};
      margin: 0 0.5rem;
    }

    .allocation-progress div {
      display: inline;
      margin-left: 0.3rem;
    }
  }
`;

export const Allocation = (props: IAllocationProps) => {
  const
    { magento: { url: magentoUrl }, mycpd: { api, url: mycpdUrl } } = useEnvironment(),
    { allocation, COMPLETION_SUMMARY_CASE_9072, REVIEWABLE_CASE_10297, USE_COURSE_IFRAMES_CASE_9404 } = props,
    course = allocation.course ? allocation.course : RemovedCourse,
    show = Object.assign({
      assessment: false,
      author: false,
      certificate: false,
      course: false,
      feedback: false,
      link: false,
      progress: false,
      showLinks: true,
      summary: false,
    }, props.show, {
      material: false,
    }),
    completed = allocation.data.completed,
    isLaunchable = course.data.isLaunchable,
    courseName = course.data.courseName,
    isPurchasable = course.data.isPurchasable,
    isReviewed = 'feedback' in allocation.data,
    isReviewable = !isReviewed && course && (REVIEWABLE_CASE_10297 ? course.data.isReviewable : course.ids.magento),
    linkType = completed ? Secondary : null,
    pClass = allocation.data.completed ? 'complete' : 'progress',
    progressClass = 'allocation-progress ' + pClass,
    al = !isLaunchable
      ? 'Expired'
      : (completed
        ? 'Review Course'
        : (allocation.data.started ? 'Resume' : 'Start')
        ),
    courseLaunchUrl = USE_COURSE_IFRAMES_CASE_9404
      ? `/course/${formatName(courseName)}/${allocation.id}`
      : `${api}/api/registration/${allocation.id}/launch`,
    actionUrl = COMPLETION_SUMMARY_CASE_9072 && completed
      ? `/completion-summary/${formatName(courseName)}/${allocation.id}`
      : isLaunchable
        ? courseLaunchUrl
        : (isPurchasable ? course.data.url : null),
    completedDate = completed ? [completed.getDate(), completed.getMonth() + 1, completed.getFullYear()].join('/') : '',
    assessmentStatus = completed
      ? 'Passed'
      : (isLaunchable ? 'Pending' : 'Expired');

  const star = <svg viewBox='0 0 16 15'><path d='M8 11.2l-4.702 3.272L4.957 8.99.392 5.528l5.727-.117L8 0l1.88 5.411 5.728.117-4.565 3.46 1.66 5.484z' fill='currentColor' fillRule='nonzero' /></svg>

  let feedback: any = 'Not Available';
  if (isReviewed) {
    feedback = 'Thank you for your feedback';
  } else if (isReviewable) {
    feedback = completed
      ? <a href={`${magentoUrl}/catalog/product/review?id=${course.ids.magento}`}>{star}{star}{star}{star}{star}<div>Rate this course</div></a>
      : 'Pending';
  }

  function progress(first = true) {
    if (allocation.data.started) {
      if (completed) {
        return first ? '100%' : 'Complete';
      } else {
        return first ? 'In' : 'Progress';
      }
    } else {
      return first ? 'Not' : 'Started';
    }
  }

  return (
    <StyledAllocation>
      {show.course && <th className='allocation-course'>
        <Course {...ioFromCourse(course, actionUrl)}/>
      </th>}
      {show.author && <td className='allocation-author'><span>Author</span><div>{course.data.authorName}</div></td>}
      {show.progress && <td className={progressClass}>{progress() + ' ' + progress(false)}</td>}
      {show.link && <td className='allocation-link'>
        {COMPLETION_SUMMARY_CASE_9072 && completed && <Button to={actionUrl} type={linkType}>{al}</Button>}
        {(!COMPLETION_SUMMARY_CASE_9072 || !completed) && <Button href={actionUrl} type={linkType}>{al}</Button>}
      </td>}
      {show.assessment && <td className='progress'>
        {assessmentStatus && <span>{assessmentStatus}</span>}
        {allocation.data.scored && <div>{allocation.data.score}%</div>}
      </td>}
      {show.summary && <td className='progress'>
        <span>INTERACTIVE ONLINE CPD</span>
        <div>COMPLETED ON {completedDate}</div>
      </td>}
      {show.certificate && <td className='allocation-certificate'>
        {completed ? <Button href={`${mycpdUrl}/certificate/${allocation.id}`} target={'_blank'}>Certificate</Button> : 'Pending'}
      </td>}
      {show.feedback && <td className='allocation-feedback'>
        {feedback}
      </td>}
    </StyledAllocation>
  );
};

export default Allocation;
