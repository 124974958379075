import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../theme';

/* eslint-disable-next-line */
export interface ButtonProps {
  children?: React.ReactElement | string;
  className?: string;
  href?: string;
  maxWidth?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  to?: string;
  target?: string;
  type?: ButtonType;
  value?: number | string;
}

enum ButtonType {
  Primary,
  Secondary,
  Tertiary,
  Quartary,
}

export const
  Primary = ButtonType.Primary,
  Secondary = ButtonType.Secondary,
  Tertiary = ButtonType.Tertiary,
  Quartary = ButtonType.Quartary;

const
  MapClassName = {
    [ButtonType.Primary]: null,
    [ButtonType.Secondary]: 'b2',
    [ButtonType.Tertiary]: 'b3',
    [ButtonType.Quartary]: 'b4',
  },
  StyledButton = styled.a<any>`
    background: ${theme.blueDarkest};
    border: 1px solid ${theme.blue};
    border-radius: ${theme.radius};
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6rem;
    padding: 1.1rem 1.3rem;
    margin: 0 ${theme.tableColumn};
    text-align: center;
    text-decoration: none;
    transition: 0.2s ${theme.easing} all;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;

    &:first-of-type {
      margin-top: 0;
      margin-left: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }

    &.${MapClassName[ButtonType.Secondary]} {
      background: ${theme.blue};
      &:hover {
        background: ${theme.blueDark};
      }
    }

    &.${MapClassName[ButtonType.Tertiary]} {
      background: ${theme.orange};
      border-color: ${theme.orange};
    }

    &.${MapClassName[ButtonType.Quartary]} {
      background: white;
      border-color: ${theme.orange};
      color:  ${theme.orange};
    }

    @media only screen and ${theme.notLargeDevice} {
      display: block;
      margin: 0.8rem 0;
    }
  `;

export const Button = (props: ButtonProps) => {
  const
    isLargeDevice = theme.isLargeDevice(),
    as = "to" in props ? Link : null,
    classNames = [],
    style = {};

  if ("type" in props) {
    classNames.push(MapClassName[props.type]);
  }
  if ("className" in props) {
    classNames.push(props.className);
  }
  const className = classNames.length ? classNames.join(' ') : null;

  if (isLargeDevice) {
    if ("maxWidth" in props) {
      style['maxWidth'] = props.maxWidth;
    }
  }

  return (
    <StyledButton as={as} className={className} data-value={props.value} href={props.href} onClick={props.onClick} style={style} target={props.target} to={props.to}>{props.children}</StyledButton>
  );
};

export default Button;
