import styled from 'styled-components';
import { IExternalCpdRecordProps, MCompetencyType } from '@lawcpd/learner/shared/data';
import { theme } from '../theme';
import { useExternalCpdRecords } from '@lawcpd/learner/shared/provider';

const
  lhPoints = "1.6rem",
  sizePoints = "1.8rem";

const StyledExternalCPD = styled.tr`
  font-size: 1.4rem;

  th, td {
    box-sizing: border-box;
    padding: ${theme.tableRow} ${theme.tableColumn};
    vertical-align: middle;
  }

  th {
    font-weight: normal;
    padding-left: 0;
    text-align: left;
  }

  td {
    min-width: ${theme.buttonWidth};
    text-align: center;
    white-space: nowrap;
    width: ${theme.buttonWidth};

    &:last-child {
      padding-right: 0;
    }
  }

  .external-cpd-completed, .external-cpd-activity-type,
  .external-cpd-stacked-progress {
    color: #918e8e;
  }

  .external-cpd-remove svg {
    stroke: #918e8e;
    cursor: pointer;
  }

  @media only screen and ${theme.notLargeDevice} {
    align-content: space-between;
    align-items: center;
    border: 1px solid #3f3f3c;
    border-radius: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding: 10px;
    margin-bottom: 10px;

    th {
      width: 100%;
    }

    td {
      padding: 0 ${theme.tableColumn};
      margin: 0 0.5rem;
    }
  }
`;

const StyledRecord = styled.header`
  div {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  div span {
    margin: 0 0.4rem 0 0;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    padding: 0;
    margin: 0;

    a {
      color: ${theme.font};
      text-decoration: none;
    }
  }

  img {
    float: left;
    margin: 0 0.6rem 0 0;
  }

  @media only screen and ${theme.notLargeDevice} {
    img {
      margin-top: 0.6rem;
    }
  }

  .competency {
    color: ${theme.orange};
  }

  .cpd-points {
    display: inline-block;
  }

  .points {
    background: ${theme.orange};
    border-radius: 50%;
    color: white;
    display: inline-block;
    font-weight: 500;
    height: ${sizePoints};
    line-height: ${lhPoints};
    text-align: center;
    width: ${sizePoints};
  }
`;

export const ExternalCpdRecord = (props: IExternalCpdRecordProps) => {
  const
    { record, DISPLAY_EXTERNAL_CPD_11361} = props,
    show = Object.assign({
      record: true,
      activityType: true,
      completed: true,
      remove: true,
      stackedCompletedActivityType: false,
    }, props.show),
    { deleteRecord } = useExternalCpdRecords(DISPLAY_EXTERNAL_CPD_11361),
    completed = record?.data?.completed ? new Date(record.data.completed) : null,
    completedDate = completed ? [completed.getDate(), completed.getMonth() + 1, completed.getFullYear()].join('/') : '',
    competencyType = record.data.competency,
    competency = competencyType ? MCompetencyType[competencyType] : "Unassigned";

  const bin = (<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="icon icon-tabler icons-tabler-outline icon-tabler-trash-x">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7h16" />
      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
      <path d="M10 12l4 4m0 -4l-4 4" />
    </svg>);

  function showCompletedAndActivityType(){
    if(show.stackedCompletedActivityType && show.completed && show.activityType){
      return (
        <td className='external-cpd-stacked-progress'>
          <span>{record?.data?.activityCpdType.toLocaleUpperCase()}</span>
          <div>{completedDate}</div>
        </td>
      )
    }
    else{
      return (
        <>
        {show.completed && <td className='external-cpd-completed'>
          {completedDate}
        </td>}
        {show.activityType && <td className='external-cpd-activity-type'>
          {record?.data?.activityCpdType}
        </td>}
        </>
      )
    }
  }

  return (
    <StyledExternalCPD>
      {show.record && <th className='allocation-course'>
        <StyledRecord>
          <div>
            <span className="competency">{competency}</span>
            <span>|</span>
            <span className="cpd-points">CPD Points</span>
            <div className="points">{record.data.cpdPoints}</div>
          </div>
          <h1>
            {record.data.name} - {record.data.provider}
          </h1>
        </StyledRecord>
      </th>}
      {showCompletedAndActivityType()}
      {show.remove && <td className='external-cpd-remove' onClick={() => deleteRecord(record.id)}>
        {bin}
      </td>}
    </StyledExternalCPD>
  );
};

export default ExternalCpdRecord;
