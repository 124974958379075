import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { DeleteCommand, DynamoDBDocumentClient, GetCommand, PutCommand, ScanCommand, UpdateCommand } from '@aws-sdk/lib-dynamodb';
import { FeatureFlagsLayer } from './feature-flags';

const
  client = new DynamoDBClient({ region: 'ap-southeast-2' }),
  ddbDocClient = DynamoDBDocumentClient.from(client);

export async function createFeatureFlags(table: string, id: string, featureFlags: FeatureFlagsLayer): Promise<boolean> {
  try {
    return await ddbDocClient.send(
      new PutCommand({
        ConditionExpression: 'attribute_not_exists(id)',
        Item: {
          id: id,
          featureFlags: featureFlags,
        },
        TableName: table,
      })
    )
    .then(({ $metadata: { httpStatusCode } }) => httpStatusCode === 200);
  } catch(e) {
    return false;
  }
}

export async function deleteFeatureFlags(table: string, id: string): Promise<boolean> {
  return await ddbDocClient.send(
    new DeleteCommand({
      Key: {
        id: id,
      },
      TableName: table,
    })
  )
  .then(({ $metadata: { httpStatusCode } }) => httpStatusCode === 200);
}

export async function deleteFeatureFlagsByPrefix(table: string, prefix: string) : Promise<boolean> {
  return await ddbDocClient.send(
    new ScanCommand({
      ExpressionAttributeValues: {
        ':prefix': prefix,
      },
      FilterExpression: 'begins_with(id, :prefix)',
      ProjectionExpression: 'id',
      TableName: table,
    })
  )
  .then(({ Items }) => Promise.all(Items.map(({ id }) => deleteFeatureFlags(table, id))))
  .then(results => results.reduce((acc, cur) => acc && cur, true));
}

export async function readFeatureFlags(table: string, id: string) : Promise<FeatureFlagsLayer> {
  return await ddbDocClient.send(
    new GetCommand({
      Key: {
        id: id,
      },
      TableName: table,
    })
  )
  .then(({ Item }) => Item && ('featureFlags' in Item) ? Item.featureFlags : {})
  .catch(e => { console.log(e); return {}; });
}

export async function updateFeatureFlags(table: string, id: string, featureFlags: FeatureFlagsLayer): Promise<boolean> {
  try {
    return await ddbDocClient.send(
      new UpdateCommand({
        ConditionExpression: 'attribute_exists(id)',
        ExpressionAttributeValues: {
          ':featureFlags': featureFlags,
        },
        Key: {
          id: id,
        },
        TableName: table,
        UpdateExpression: 'set featureFlags = :featureFlags',
      })
    )
    .then(({ $metadata: { httpStatusCode } }) => httpStatusCode === 200);
  } catch(e) {
    return false;
  }
}
