import ReactDOM from 'react-dom/client';
import { EnvironmentProvider } from '@lawcpd/learner/shared/provider';
import { FirebaseAuthProvider } from 'support-provider';
import { environment } from './environments/environment';
import { FeatureFlagsProvider as FeatureFlagsToggleStateProvider } from './app/feature-flags/feature-flag-provider';
import App from './app/app';
import { FeatureFlagsProvider } from '@lawcpd/feature-flags/provider';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <EnvironmentProvider environment={environment}>
    <FeatureFlagsProvider useLearnerFF={false}>
      <FirebaseAuthProvider>
        <FeatureFlagsToggleStateProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </FeatureFlagsToggleStateProvider>
      </FirebaseAuthProvider>
    </FeatureFlagsProvider>
  </EnvironmentProvider>
);
