// Import the functions you need from the SDKs you need
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

class FirebaseClientApp {
  private app: FirebaseApp;
  private auth: Auth;
  private config: FirebaseOptions;

  constructor(config: string){
    try{
      this.config = JSON.parse(atob(config));
    }
    catch{ this.config = {} }
  }

  getApp(){
    if(!this.app){
      this.app = initializeApp(this.config);
    }
    return this.app;
  }

  getAuth(){
    if(!this.auth){
      this.auth = getAuth(this.getApp());
    }
    return this.auth;
  }
}

export default FirebaseClientApp;
