// The following are the same in all environments:
const
  feedback = 'https://us1.list-manage.com/survey?u=39ed81f2b2f0e945c6c341995&id=2f1da5fbf5',
  google: Google = {
    gtmId: 'GTM-WPNQTP',
  },
  help = 'https://help.lawcpd.com.au/a/solutions/articles/11000097827';


export type ApiEnvironment = {
  auth0: Auth0;
  course: {
    api: string;
    apiDomain: string;
    chatUrl: string;
    chatDomain: string;
  };
  magento: {
    api: string;
    apiDomain:string;
    url: string;
  };
  learner: {
    api: string;
    apiDomain: string;
    url: string;
  };
  support: Support;
  featureFlag: FeatureFlagEnv;
  firebase: Firebase;
  development: boolean;
  domain: string;
  dynamodb: {
    courses: string;
    coursesMap: string;
    events: string;
    eventResults: string;
    eventSupport: string;
    featureFlags: string;
    learners: string;
    learnersMap: string;
    learnersSearch: string;
    registrations: string;
    registrationsMap: string;
    externalCpds: string;
  };
  s3: {
    courses: string;
    drupalExport: string;
    backupDdb: string;
  };
  mailchimp: string;
  loginUrl: string;
  production: boolean;
  scormCloud: ScormCloud;
};

export interface Auth0 {
  api: string; // Use custom domain
  lawcpdApi: string;
  lawcpdApiDomain: string;
  apiClientId: string;
  apiDomain: string; // Use Auth0 domain
  clientId: string;
  tenant: string;
  connection: string;
  domain: string;
}

export interface Firebase {
  projectId: string;
  serviceAccountId: string;
  config: string;
}

interface Google {
  gtmId: string;
}

interface Login {
  login: string;
  logout: string;
  url: string;
}

interface Magento {
  addressUpdate: string;
  callback: string;
  ethics: string;
  jwt: string;
  learnerUpdate: string;
  legalcpd: string;
  login: string;
  logout: string;
  orderHistory: string;
  practiceManagement: string;
  professionalSkills: string;
  requirements: string;
  rewardAbout: string;
  rewardHistory: string;
  substantiveLaw: string;
  url: string;
}

interface MyCpd {
  api: string;
  apiDomain;
  callback: string;
  feedback: string;
  login: string;
  logout: string;
  url: string;
}
export interface Support {
  api: string;
  apiDomain: string;
  login: string;
  loginDomain: string;
  domain: string;
  url: string;
}

export interface ScormCloud {
  api: string;
  apiDomain: string;
  appId: string;
  adminAppId: string;
}

export interface FeatureFlagEnv {
  api: string;
  apiDomain: string;
  flagsUrl: string;
  flagsDomain: string;
  dashboardUrl: string;
  dashboardDomain: string;
}

export interface Environment {
  auth0: Auth0;
  assetsDomain: string;
  apiDomain: string;
  course: {
    api: string;
    apiDomain: string;
    chatUrl: string;
    chatDomain: string;
  };
  firebase: Firebase;
  development: boolean;
  domain: string;
  magento: {
    api: string;
    apiDomain: string;
    url: string;
  };
  login: string;
  mycpd: {
    api: string;
    apiDomain: string;
    url: string;
  };
  support: Support;
  featureFlag: FeatureFlagEnv;
  production: boolean;
  scormCloud: ScormCloud;
}

export interface EnvironmentExpanded {
  auth0: Auth0;
  firebase: Firebase;
  development: boolean;
  cookiesDomain: string;
  assets: string;
  course: {
    api: string;
    apiDomain: string;
    chatUrl: string;
    chatDomain: string;
  };
  domain: string;
  google: Google;
  help: string;
  login: Login;
  magento: Magento;
  mycpd: MyCpd;
  support: Support;
  featureFlag: FeatureFlagEnv;
  production: boolean;
  scormCloud: ScormCloud;
}

export function env(
  auth0ApiClientId: string, auth0ClientId: string, auth0Connection: string, tenant: string,
  fireBaseProjectId: string, fireBaseServiceAccountId: string, fireBaseConfig: string,
  domain: string, production: boolean, scormCloudAppId: string, scormCloudAdminAppId: string): Environment {
  return {
    firebase: {
      projectId: fireBaseProjectId,
      serviceAccountId: fireBaseServiceAccountId,
      config: fireBaseConfig
    },
    assetsDomain: `assets.${domain}`,
    apiDomain: `api.${domain}`,
    auth0: {
      api: `https://signin.${domain}`,
      lawcpdApi: `https://auth0.api.${domain}`,
      lawcpdApiDomain: `auth0.api.${domain}`,
      apiClientId: auth0ApiClientId,
      apiDomain: `${tenant}.au.auth0.com`,
      clientId: auth0ClientId,
      connection: auth0Connection,
      tenant,
      domain: `signin.${domain}`,
    },
    development: false,
    domain: domain,
    magento: {
      api: `https://magento.api.${domain}`,
      apiDomain: `magento.api.${domain}`,
      url: `https://${domain}`
    },
    login: `https://login.${domain}`,
    mycpd: { // Learner Dashboard
      api: `https://api.${domain}`,
      apiDomain: `api.${domain}`,
      url: `https://mycpd.${domain}`,

    },
    course: {
      api: `https://course.api.${domain}`,
      apiDomain: `course.api.${domain}`,
      chatUrl: `https://chat.${domain}`,
      chatDomain: `chat.${domain}`
    },
    support: { // Support Dashboard
      api: `https://support.api.${domain}`,
      apiDomain: `support.api.${domain}`,
      url: `https://support.${domain}`,
      domain: `support.${domain}`,
      login: `https://support-login.${domain}`,
      loginDomain: `support-login.${domain}`
    },
    featureFlag: { // Feature flag Dashboard
      api: `https://feature.api.${domain}`,
      apiDomain: `feature.api.${domain}`,
      flagsUrl: `https://feature-flags.${domain}`,
      flagsDomain: `feature-flags.${domain}`,
      dashboardUrl: `https://feature.${domain}`,
      dashboardDomain: `feature.${domain}`
    },
    production: production,
    scormCloud: {
      api: `https://scorm.api.${domain}`,
      apiDomain: `scorm.api.${domain}`,
      appId: scormCloudAppId,
      adminAppId: scormCloudAdminAppId,
    }
  };
}

export function expand(e: Environment): EnvironmentExpanded {
  return {
    auth0: e.auth0,
    firebase: e.firebase,
    development: e.development,
    course: e.course,
    assets: e.assetsDomain,
    domain: e.domain,
    cookiesDomain: e.development ? 'localhost': e.domain,
    google: google,
    help: help,
    login: {
      login: `${e.login}/login/mycpd`,
      logout: `${e.login}/logout/mycpd`,
      url: e.login,
    },
    magento: {
      addressUpdate: `${e.magento.url}/customer/address`,
      callback: `${e.magento.url}/customer/lawcpd/callback`,
      ethics: `${e.magento.url}/legal-cpd/ethics`,
      jwt: `${e.magento.url}/customer/lawcpd/jwt`,
      learnerUpdate: `${e.magento.url}/customer/account/edit`,
      legalcpd: `${e.magento.url}/legal-cpd`,
      login: `${e.magento.url}/customer/lawcpd/login`,
      logout: `${e.magento.url}/customer/lawcpd/logout`,
      orderHistory: `${e.magento.url}/sales/order/history`,
      practiceManagement: `${e.magento.url}/legal-cpd/practice-management`,
      professionalSkills: `${e.magento.url}/legal-cpd/professional-skills`,
      requirements: `${e.magento.url}/cpd-points-requirements-lawyers`,
      rewardAbout: `${e.magento.url}/about-myrewards`,
      rewardHistory: `${e.magento.url}/reward/customer/info`,
      substantiveLaw: `${e.magento.url}/legal-cpd/substantive-law`,
      url: e.magento.url,
    },
    mycpd: {
      ...e.mycpd,
      callback: `${e.mycpd.url}/`,
      feedback: feedback,
      login: `${e.mycpd.url}/login`,
      logout: `${e.mycpd.url}/logout`
    },
    support: e.support,
    featureFlag: e.featureFlag,
    production: e.production,
    scormCloud: e.scormCloud,
  };
}
