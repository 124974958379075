import {
  PhoneMultiFactorGenerator, PhoneAuthProvider,multiFactor, User, getMultiFactorResolver,
   MultiFactorError, MultiFactorResolver, UserCredential, MultiFactorInfo, RecaptchaVerifier, PhoneInfoOptions, Auth
} from 'firebase/auth';

export type MFASignIn = {
  resolver: MultiFactorResolver;
  verificationId: string;
}

export function getMFAResolver(authApp: Auth, error: MultiFactorError){
  return getMultiFactorResolver(authApp, error);
}

export function getMultiFactorSession(user: User){
  return multiFactor(user).getSession();
}

export function getMFAToUse(resolver: MultiFactorResolver, index: number){
  return resolver.hints[index].factorId;
}

export function getMFAsEnrolled(user: User): MultiFactorInfo[]{
  return multiFactor(user).enrolledFactors;
}

export function invisibleRecaptchaVerifier(authApp: Auth, container: string | HTMLElement){
  return new RecaptchaVerifier(container, {
    size: "invisible"
  }, authApp);
}

export async function enrollMFAPhoneNumber(authApp: Auth, user: User, phoneNumber: string, recaptcha: RecaptchaVerifier): Promise<string>{

  /**
   * 1. Recaptcha
   * 2. MFA Session
   * 3. Phone Info Session
   * 4. SMS Verification of Phone
   * 5. Reset captcha when verify fails
   * 6. Verify SMS Code
   * 7. MFA Assertion
   * 8. Enroll
   */
  const session = getMultiFactorSession(user);

  return session.then((mfaSession) => {
    const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: mfaSession
      };

    return sendPhoneVerification(authApp, phoneInfoOptions, recaptcha);
  });
}

/**
 * Verifies and enrolls the user to the phone MFA
 * @param user The user initiating the enrollment of the MFA
 * @param verificationId The verification ID of the Phone MFA
 * @param verificationCode The verification code sent to the user via SMS
 * @returns Nothing if successful, a rejected promise if there's any errors
 */
export function verifyPhoneAuthCodeEnrollment(user: User, verificationId: string, verificationCode: string){
  const
    cred = PhoneAuthProvider.credential(verificationId, verificationCode),
    mfaAssertion = PhoneMultiFactorGenerator.assertion(cred);

  return multiFactor(user).enroll(mfaAssertion, 'LawCPD Support Dashboard');
}

/**
 * Verifies and sign ins the user
 * @param resolver The MFA resolver
 * @param verificationId The verification ID of the Phone MFA
 * @param verificationCode The verification code sent to the user via SMS
 * @returns a promise of the user's credentials
 */
export function verifyPhoneAuthCodeSignIn(resolver: MultiFactorResolver, verificationId: string, verificationCode: string): Promise<UserCredential>{
  const
    cred = PhoneAuthProvider.credential(verificationId, verificationCode),
    mfaAssertion = PhoneMultiFactorGenerator.assertion(cred);

  return resolver.resolveSignIn(mfaAssertion)
  .catch((error) => {
    if(error.code === 'auth/invalid-verification-code' || error.code === 'auth/missing-code'){
      return Promise.reject(new Error('Invalid verification code'));
    }
    else{
      return Promise.reject(error)
    }
  });
}

export async function signInWithMFA(authApp: Auth, error: MultiFactorError, index: number = 0, recaptcha: RecaptchaVerifier): Promise<MFASignIn>{
  const
    resolver = getMultiFactorResolver(authApp, error),
    mfaToUse = getMFAToUse(resolver, index);

  if(mfaToUse === PhoneMultiFactorGenerator.FACTOR_ID){
    const
      phoneInfoOptions: PhoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session
      },
      verificationID = await sendPhoneVerification(authApp, phoneInfoOptions, recaptcha);

    return {
      resolver: resolver,
      verificationId: verificationID
    }
  }
  else{
    throw Error(`Unknown MFA (${mfaToUse}) not supported`);
  }
}

export async function sendPhoneVerification(authApp: Auth, phoneInfoOptions: PhoneInfoOptions, recaptcha: RecaptchaVerifier): Promise<string>{
  try{
    const
      phoneAuthProvider = new PhoneAuthProvider(authApp),
      verificationID = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha);

    return verificationID;
  }
  catch(error){
    if(error.code === 'auth/invalid-phone-number'){
      return Promise.reject(new Error('Invalid phone number provided'));
    }
    else{
      return Promise.reject(error);
    }
  }
}

