import React, { useEffect, useState } from 'react';
import {
  allocationFromRegistration, AnnotatedAllocation,
} from '@lawcpd/learner/shared/data';
import { AllocationsContext } from './allocations';
import { useAuth0 } from '../auth0/auth0';
import { useEnvironment } from '../environment/environment';
import { useLearner } from '../learner/learner';

interface IAllocationsProviderOptions {
  children: React.ReactElement;
}

export const AllocationsProvider = ({
  children,
}: IAllocationsProviderOptions) => {
  const
    { getIdTokenClaims } = useAuth0(),
    { mycpd: { api } } = useEnvironment(),
    { learnerId } = useLearner(),
    [allocations, setAllocations] = useState<AnnotatedAllocation[]>([]),
    [error, setError] = useState(false),
    [loading, setLoading] = useState(true);

  useEffect(() => {
    if (learnerId) {
      const apiPath = `${api}/api/learner/${encodeURI(learnerId)}/registration`;
      getIdTokenClaims()
        .then(idToken => fetch(apiPath, { headers: { Authorization: `Bearer ${idToken.__raw}` } }))
        .then(_ => _.json())
        .then(response => {
          if ('error' in response) {
            setError(true);
          } else {
            setAllocations(response[0].map(allocationFromRegistration));
          }
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    }
  }, [learnerId]);

  return (
    <AllocationsContext.Provider
      value={{
        allocations: allocations,
        error: error,
        loading: loading,
      }}
    >
      {children}
    </AllocationsContext.Provider>
  );
};

export default AllocationsProvider;
