// Production shared environment variables.
import { env } from '../lib/environment';
export function prod() {
  return env(
    '8h37zMZn7WVxhrW3XP7kUcd0dMTbfraP',
    '6xOTizJqLIyxvuemxd0DO4VOIHpoiXdx',
    'Drupal-LMS',
    'lawcpd',
    'support-login-prod',
    'firebase-adminsdk-d7qz0@support-login-prod.iam.gserviceaccount.com',
    'eyJhcGlLZXkiOiJBSXphU3lEUUNKeVBueW9jTUYxOUNJdVh3OXVRMWRtT0c4MWd3cmciLCJhdXRoRG9tYWluIjoic3VwcG9ydC1sb2dpbi1wcm9kLmZpcmViYXNlYXBwLmNvbSIsInByb2plY3RJZCI6InN1cHBvcnQtbG9naW4tcHJvZCIsInN0b3JhZ2VCdWNrZXQiOiJzdXBwb3J0LWxvZ2luLXByb2QuYXBwc3BvdC5jb20iLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjExMTI4MDExMDEwOCIsImFwcElkIjoiMToxMTEyODAxMTAxMDg6d2ViOmFjZjM0M2VlZjA3NTVhZjQ4NDNkZDkiLCJtZWFzdXJlbWVudElkIjoiRy1IOVk5NzNON01QIn0=',
    'lawcpd.com.au',
    true,
    'T0TWFRMEYG', // LawCPD Production: T0TWFRMEYG, SCORM Cloud: WRL78OJQWA, Production Test: FBA4RPPOCG.
    'RS6SN9FIP6',
  );
}
