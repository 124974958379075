import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useEnvironment, useLearner } from '@lawcpd/learner/shared/provider';
import { theme } from '../theme';

const StyledWelcome = styled.h1`
  flex-grow: 1;
  font-size: 2rem;
  margin: 0;

  a {
    color: ${theme.orange};
    text-decoration: none;
  }

  @media only screen and ${theme.notLargeDevice} {
    background-color: white;
    box-sizing: border-box;
    height: 4.6rem;
    padding: 0.8rem 0.8rem;
    text-align: center;
    vertical-align: center;

    span:first-child {
      display: none;
    }

    a:before {
      color: ${theme.font};
      content: "Hi ";
    }
  }
`;

const StyledLearner = styled.div`
  flex-basis: 48rem;
  flex-shrink: 0;
  font-size: 1.25rem;
  grid-area: learner;
  margin: 0;

  div {
    display: flex;

    a {
      color: theme.font;
      text-decoration: none;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      white-space: nowrap;
    }

    li:after {
      content: ", ";
      white-space: pre;
    }

    li:last-child:after {
      content: normal;
    }
  }

  @media only screen and ${theme.notLargeDevice} {
    display: none;
  }
`;

//margin-right: ${theme.gutter};
export const Learner = () => {
  const
    { magento: { url } } = useEnvironment(),
    { discipline, email, givenName, jurisdiction } = useLearner();
  return (
    <>
      <StyledWelcome ><span>Welcome to MyCPD</span> <a href={`${url}/customer/account/edit`}>{givenName}</a></StyledWelcome>
      <StyledLearner >
        <div>
          <strong>
            <NavLink to="/profile">Learner Profile:</NavLink>
          </strong>
        </div>
        <ul>
          <li>{discipline}</li>
          <li>{jurisdiction}</li>
          <li>{email}</li>
        </ul>
      </StyledLearner>
    </>
  );
};

export default Learner;
