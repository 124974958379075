import React from 'react';

import styled from 'styled-components';

import { theme } from '../theme';

/* eslint-disable-next-line */
export interface CompliancePointsProps {
  cpdPoints: number;
}

const StyledCompliancePoints = styled.div`
  align-items: center;
  background-color: ${theme.blue};
  border: 1px solid ${theme.blue};
  border-radius: ${theme.buttonWidthHalf};
  color: #fff;
  display: flex;
  font-size: 1.6rem;
  height: ${theme.buttonWidth};
  justify-content: center;
  line-height: 2rem;
  width: ${theme.buttonWidth};

  div {
    text-align: center;

    div {
      font-size: 4rem;
      line-height: 4rem;
      margin-top: -0.6rem;
    }
  }

  @media only print {
    background-color: #fff;
    border: 1px solid ${theme.blueDarkest};
    color: ${theme.blueDarkest};
  }
`;

export const CompliancePoints = (props: CompliancePointsProps) => {
  return (
    <StyledCompliancePoints className="compliance-points">
      <div>
        <div><strong>{props.cpdPoints}</strong>/10</div>
        CPD Points
      </div>
    </StyledCompliancePoints>
  );
};

export default CompliancePoints;
