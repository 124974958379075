import React, { useContext } from 'react';
import { FeatureFlags } from '@lawcpd/feature-flags';

export type IFeatureFlagsContext = {
  error: boolean;
  featureFlags: FeatureFlags;
  loading: boolean;
}

export const FeatureFlagsContext = React.createContext<IFeatureFlagsContext | null>(null);
export const useFeatureFlags = () => useContext(FeatureFlagsContext);
