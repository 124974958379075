import { ICourse } from '@lawcpd/learner/shared/data';
import { CoursesContext } from "./courses";

export const StorybookCoursesProvider = (Story, context) => {
  const { coursesError, coursesLoading, courses } = context.args;

  // Controls at the moment doesn't support array of object with controls
  // for (let i = 0; i < courses.length; i++) {
  //   const
  //     course = courses[i],
  //     n = i + 1,
  //     gCourse = 'Course ' + n;

  //   course.metadata.created = myDateKnob(date, "Created", course.metadata.created, gCourse);
  //   course.metadata.updated = myDateKnob(date, "Updated", course.metadata.updated, gCourse);
  //   course.data.contentVersion = myDateKnob(date, "Version", course.data.contentVersion, gCourse);
  //   course.data.courseName = text("Name", course.data.courseName, gCourse);
  //   course.data.competency = text("Competency", course.data.competency, gCourse);
  //   course.data.competencyType = myCompetencyTypeKnob(optionsKnob, course.data.competencyType, gCourse);
  //   course.data.cpdPoints = number("CPD Points", course.data.cpdPoints, { range: true, min: 0, max: 10, step: 0.5 }, gCourse);
  //   course.data.thumbnailUrl = text("Thumbnail URL", course.data.thumbnailUrl, gCourse);
  //   course.data.authorName = text("Author Name", course.data.authorName, gCourse);
  //   courseMap['lawcpd-1-' + course.ids.magento.toString()] = course;
  // }

  return (
    <CoursesContext.Provider
      value={{
        courses: courses,
        error: coursesError,
        loading: coursesLoading,
      }}
    >
      <Story/>
    </CoursesContext.Provider>
  );
};

type CoursesArgTypes = {
  name: string;
  control: "object" | "boolean",
  table: {
    category: "Courses"
    subcategory?: "States"
  }
}

export interface IStoryCoursesContext {
  courses: ICourse[],
  coursesLoading: boolean,
  coursesError: boolean,
}

export const StoryBookCoursesArgs: IStoryCoursesContext = {
  courses: [],
  coursesLoading: false,
  coursesError: false,
}

export const StoryBookCoursesArgTypes: Record<keyof IStoryCoursesContext, CoursesArgTypes> = {
  courses : {
    name: "Courses",
    control: "object",
    table: {
      category: "Courses"
    }
  },
  coursesLoading : {
    name: "Loading?",
    control: "boolean",
    table: {
      category: "Courses",
      subcategory: "States"
    }
  },
  coursesError : {
    name: "Error?",
    control: "boolean",
    table: {
      category: "Courses",
      subcategory: "States"
    }
  }
};

export default StorybookCoursesProvider;
