import { FeatureFlagsLayer } from '@lawcpd/feature-flags';
import { Auth0 } from './environment';

export enum EAreaOfExpertise {
  Advocacy,
  AlternativeDisputeResolution,
  CorporateAndCommercialLaw,
  CriminalLaw,
  EmploymentLaw,
  FamilyLaw,
  InternationalLaw,
  InHouseCounsel,
  Litigation,
  MediaAndTechnology,
  PeopleSkills,
  Property,
  WillsAndEstates,
}

export enum ECompetencyType {
  All,
  Ethics,
  ProfessionalSkills,
  PracticeManagement,
  SubstantiveLaw,
}

export interface IAddress {
  address1: string;
  address2: string;
  familyName: string;
  givenName: string;
  organisation: string;
  postcode: string;
  state: string;
}

export interface IAllocation {
  courseId: string;
  data: {
    completed?: Date;
    feedback?: Date;
    progress: number;
    score?: number;
    scored?: Date;
    started?: Date;
  };
  featureFlags?: FeatureFlagsLayer;
  hash?: string;
  id: string;
  ids: {
    drupal?: number; // Drupal is being deprecated. New registrations will not have a Drupal id, and it will mean little going forward.
    scorm?: string; // sha3-512 hash we will generate.
  };
  learnerId: string;
  metadata: {
    created: Date;
    updated: Date;
  };
}

export type AnnotatedAllocation = IAllocation & {
  course: ICourse;
}

export type IoRegistration = {
  courseId: string;
  data: {
    completed?: number;
    feedback?: number;
    progress: number;
    score?: number;
    scored?: number;
    started?: number;
  };
  cpdYear?: number;
  featureFlags?: FeatureFlagsLayer;
  hash?: string;
  id: string;
  ids: {
    drupal?: number; // Drupal is being deprecated. New registrations will not have a Drupal id, and it will mean little going forward.
    scorm?: string; // sha3-512 hash we will generate.
  };
  learnerId: string;
  metadata: {
    created: number;
    updated: number;
  };
}

export type AnnotatedRegistration = IoRegistration & {
  course: IoCourse;
}

export interface IAllocationContext {
  allocations: AnnotatedAllocation[];
  error: boolean;
  loading: boolean;
}

export interface IAllocationProps {
  allocation: AnnotatedAllocation;
  show?: IAllocationShow;
  COMPLETION_SUMMARY_CASE_9072: boolean;
  REVIEWABLE_CASE_10297: boolean;
  USE_COURSE_IFRAMES_CASE_9404: boolean;
}

export interface IAllocationsProps {
  allocations: AnnotatedAllocation[];
  message?: string;
  show?: IAllocationShow;
  showCTA?: boolean;
  urlCTA?: string;
  COMPLETION_SUMMARY_CASE_9072: boolean;
  REVIEWABLE_CASE_10297: boolean;
  USE_COURSE_IFRAMES_CASE_9404: boolean;
}

export interface IExternalCpdRecordProps {
  record: IExternalCpd;
  show?: IExternalCpdShow;
  DISPLAY_EXTERNAL_CPD_11361: boolean;
}
export interface IExternalCpdRecordsProps {
  records: IExternalCpd[];
  launchPopup?: (e) => void;
  show?: IExternalCpdShow;
  DISPLAY_EXTERNAL_CPD_11361: boolean;
}
export interface IExternalCpdContext {
  records: IExternalCpd[],
  error: boolean;
  loading: boolean;
  deleteRecord: (recordId: string) => void;
}

export type IExternalCpd = {
  id: string;
  learnerId: string;
  data: IExternalCpdData,
  metadata: {
    updated: Date;
    created: Date;
  }
}

export type IExternalCpdData = {
  completed: Date;
  name: string;
  cpdPoints: number;
  competency?: ECompetencyType;
  activityCpdType: string;
  provider: string;
}

export type IoExternalCpd = {
  id: string;
  learnerId: string;
  data: IoExternalCpdData,
  metadata: {
    updated: number;
    created: number;
  }
}

export type IoExternalCpdData = {
  completed: number;
  name: string;
  cpdPoints: number;
  competency?: string;
  activityCpdType: string;
  provider: string;
};

export interface IExternalCpdShow {
  record?: boolean;
  completed?: boolean;
  activityType?: boolean;
  remove?: boolean;
  stackedCompletedActivityType?: boolean;
}

export interface IAllocationShow {
  assessment?: boolean;
  author?: boolean;
  certificate?: boolean;
  course?: boolean;
  feedback?: boolean;
  link?: boolean;
  material?: boolean;
  progress?: boolean;
  showLinks?: boolean;
  summary?: boolean;
}

export interface AssessmentProps {
  isAvailable: boolean;
  result?: ResultProps;
  url?: string;
}

export type ICourseData = {
  areaOfExpertise: EAreaOfExpertise[];
  authorName?: string;
  competency?: string;
  competencyType: ECompetencyType[];
  contentVersion: Date;
  courseName: string;
  cpdPoints: number;
  description?: string;
  imageUrl?: string;
  isLaunchable: boolean;
  isPurchasable: boolean;
  isReviewable: boolean;
  price?: number;
  rating?: number;
  sku?: string;
  summary?: string;
  thumbnailName?: string;
  thumbnailUrl?: string;
  type: string;
  url?: string;
  courseMaterialsUrl?: string;
}

export type ICourse = {
  data: ICourseData;
  featureFlags?: FeatureFlagsLayer;
  hash?: string;
  id: string;
  ids: {
    drupal?: number; // Drupal is being deprecated. New courses will not have a Drupal id, and it will mean little going forward.
    magento?: number; // All courses should be in Magento, but we will need to look up their Magento id on migration.
    scorm?: string; // We will generate these. Initially 'scorm-1-<magento>'.
  };
  metadata: {
    created: Date;
    updated: Date;
  };
};

export interface ICourseContext {
  courses: Record<string, ICourse>;
  error: boolean;
  loading: boolean;
}

export type IoCourse = {
  data: {
    areaOfExpertise: string[];
    authorName?: string;
    competency?: string;
    competencyType: string[];
    contentVersion: number;
    courseName: string;
    cpdPoints: number;
    description?: string;
    imageUrl?: string;
    isLaunchable: boolean;
    isPurchasable: boolean;
    isReviewable: boolean;
    price?: number;
    rating?: number;
    sku?: string;
    summary?: string;
    thumbnailName?: string;
    thumbnailUrl?: string;
    type: string;
    url?: string;
  };
  featureFlags?: FeatureFlagsLayer;
  hash?: string;
  id: string;
  ids: {
    drupal?: number; // Drupal is being deprecated. New courses will not have a Drupal id, and it will mean little going forward.
    magento?: number; // All courses should be in Magento, but we will need to look up their Magento id on migration.
    scorm?: string; // We will generate these. Initially 'scorm-1-<magento>'.
  };
  metadata: {
    created: number;
    updated: number;
  };
};

export type IoLearner = {
  data: {
    discipline: string,
    jurisdiction: string,
    addresses?: {
      address1?: string;
      address2?: string;
      city?: string;
      country?: string;
      postcode?: string;
    }
    email: string;
    familyName: string;
    givenName: string;
    howDidYouFindUs?: string[];
    migratedAreaOfExpertise?: string;
    mobile?: string;
    organisation?: string;
    phone?: string;
    practitionerId?: string;
    timezone: string;
    username: string;
  }
  featureFlags?: FeatureFlagsLayer;
  hash?: string;
  id: string;
  ids: {
    lawcpd?: string; // Denotes it being a secondary account.
    drupal?: number; // Drupal is being deprecated. New courses will not have a Drupal id, and it will mean little going forward.
    magento?: number; // All courses should be in Magento, but we will need to look up their Magento id on migration.
    scorm?: string; // We will generate these. Initially 'scorm-1-<magento>'.
  };
  metadata: {
    created: number;
    updated: number;
    login?: number;
    merged?: IMergedLearner[];
  };
}

export enum Discipline {
  Barrister,
  Solicitor,
}

export enum Jurisdiction {
  ACT,
  NSW,
  NT,
  Qld,
  SA,
  Tas,
  Vic,
  WA,
}

type IMergedLearner = {
  created: number;
  id: string;
}

export type ILearner = {
  data: {
    discipline: Discipline,
    jurisdiction: Jurisdiction,
    addresses?: {
      address1?: string;
      address2?: string;
      city?: string;
      country?: string;
      postcode?: string;
    }
    email: string;
    familyName: string;
    givenName: string;
    howDidYouFindUs?: string[];
    migratedAreaOfExpertise?: string;
    mobile?: string;
    organisation?: string;
    phone?: string;
    practitionerId?: string;
    timezone: string;
    username: string;
  }
  featureFlags?: FeatureFlagsLayer;
  hash?: string;
  id: string;
  ids: {
    // Duplicated in apps/learner-api/src/app/learner-types.ts
    auth0?: string; // Learners who have not logged in since the migration to Magento in 2018 will not have an Auth0 id.
    drupal?: number; // Drupal is deprecated. New learners will not have a Drupal id, and it will mean little going forward.
    lawcpd?: string; // The learner id of the primary learner account this has been merged with.
    magento?: number; // All learners should be in Magento.
    scorm?: string; // We will generate these. Initially the same as the Learner id.
  };
  metadata: {
    created: number;
    updated: number;
    login?: number;
    identities?: Record<string, string[]>, // email: Providers [primary, ...others]
    merged?: IMergedLearner[];
  };
}

export interface IEnvironmentContext {
  auth0: Auth0;
  firebase: {
    projectId: string;
    serviceAccountId: string;
    config: string;
  }
  development: boolean;
  cookiesDomain: string;
  course: {
    api: string;
    apiDomain: string;
  };
  domain: string;
  assets: string;
  google: {
    gtmId: string;
  };
  help: string;
  login: {
    login: string;
    logout: string;
    url: string;
  };
  magento: {
    addressUpdate: string;
    callback: string;
    ethics: string;
    jwt: string;
    learnerUpdate: string;
    legalcpd: string;
    login: string;
    logout: string;
    orderHistory: string;
    practiceManagement: string;
    professionalSkills: string;
    requirements: string;
    rewardAbout: string;
    rewardHistory: string;
    substantiveLaw: string;
    url: string;
  };
  mycpd: {
    api: string;
    callback: string;
    feedback: string;
    login: string;
    logout: string;
    url: string;
  };
  support: {
    api: string;
    apiDomain: string;
    login: string;
    loginDomain: string;
    domain: string;
    url: string;
  };
  featureFlag: {
    api: string;
    dashboardUrl: string;
    dashboardDomain: string;
    flagsUrl: string;
    flagsDomain: string;
  };
  production: boolean;
}


export interface JWT {
  'https://lawcpd.com.au/discipline'?: string;
  'https://coursegenius.com/uid'?: string;
  'https://lawcpd.com.au/groups'?: string[];
  'https://lawcpd.com.au/roles'?: string[];
  'https://lawcpd.com.au/email'?: string;
  'https://lawcpd.com.au/family_name'?: string;
  'https://feature.lawcpd.com.au/flags'?: FeatureFlagsLayer;
  'https://lawcpd.com.au/given_name'?: string;
  'https://lawcpd.com.au/jurisdiction'?: string;
  'https://lawcpd.com.au/id'?: string;
  'https://lawcpd.com.au/cid'?: string;
  'https://lawcpd.com.au/organisation'?: string;
  'https://lawcpd.com.au/practitioner_id'?: string;
  'https://lawcpd.com.au/isSocial'?: boolean;
  'sub': string;
}

export interface ILearnerContext {
  discipline: string;
  email: string;
  error: boolean;
  familyName: string;
  featureFlags?: FeatureFlagsLayer;
  givenName: string;
  id: string;
  jurisdiction: string;
  learnerId: string;
  loading: boolean;
  magentoId: number;
  organisation: string;
  practitionerId: string;
}

export interface ILearnerDetailsContext {
  billingAddress: IAddress;
  billingAddressError: boolean;
  loading: boolean;
  ordersRecent: IOrder[];
  ordersRecentError: boolean;
  rewardBalance: number;
  rewardBalanceError: boolean;
}

export interface IOrder {
  amount: number;
  created: Date;
  number: string;
  orderId?: string;
  items?: string[];
}

export interface ResultProps {
  date: Date;
  isEvaluated: boolean;
  isPassed: boolean;
  score: number;
  url?: string;
}

export const
  MAreaOfExpertise = {
    null: 'Unknown',
    [EAreaOfExpertise.Advocacy]: 'Advocacy',
    [EAreaOfExpertise.AlternativeDisputeResolution]: 'Alternative Dispute Resolution',
    [EAreaOfExpertise.CorporateAndCommercialLaw]: 'Corporate and Commercial Law',
    [EAreaOfExpertise.CriminalLaw]: 'Criminal Law',
    [EAreaOfExpertise.EmploymentLaw]: 'Employment Law',
    [EAreaOfExpertise.FamilyLaw]: 'Family Law',
    [EAreaOfExpertise.InHouseCounsel]: 'In-house Counsel',
    [EAreaOfExpertise.InternationalLaw]: 'International Law',
    [EAreaOfExpertise.Litigation]: 'Litigation',
    [EAreaOfExpertise.MediaAndTechnology]: 'Media and Technology',
    [EAreaOfExpertise.PeopleSkills]: 'People Skills',
    [EAreaOfExpertise.Property]: 'Property',
    [EAreaOfExpertise.WillsAndEstates]: 'Wills and Estates',
  },
  RAreaOfExpertise = Object.entries(MAreaOfExpertise).reduce((ret, [key, value]) => {
    ret[value] = key;
    return ret;
  }, {}),
  MCompetencyType = {
    null: 'Unknown',
    [ECompetencyType.All]: 'Cover All Competencies',
    [ECompetencyType.Ethics]: 'Ethics',
    [ECompetencyType.ProfessionalSkills]: 'Professional Skills',
    [ECompetencyType.PracticeManagement]: 'Practice Management',
    [ECompetencyType.SubstantiveLaw]: 'Substantive Law',
  },
  orderCompetencyType = [
    ECompetencyType.Ethics,
    ECompetencyType.PracticeManagement,
    ECompetencyType.ProfessionalSkills,
    ECompetencyType.SubstantiveLaw
  ],
  RCompetencyType = Object.entries(MCompetencyType).reduce((ret, [key, value]) => {
    ret[value] = key;
    return ret;
  }, {}),
  CompetencyUrlKey = {
    [ECompetencyType.Ethics]: 'ethics',
    [ECompetencyType.ProfessionalSkills]: 'professional-skills',
    [ECompetencyType.PracticeManagement]: 'practice-management',
    [ECompetencyType.SubstantiveLaw]: 'substantive-law',
  },
  RemovedCourse: ICourse = {
    data: {
      areaOfExpertise: [],
      authorName: "Removed",
      competency: "None",
      competencyType: [],
      contentVersion: new Date(),
      courseName: "Removed",
      cpdPoints: 0,
      isLaunchable: false,
      isPurchasable: false,
      isReviewable: false,
      summary: "Removed",
      thumbnailName: "Removed",
      thumbnailUrl: null,
      type: "virtual",
    },
    id: null,
    ids: {},
    metadata: {
      created: null,
      updated: null,
    },
  };

export function clone<Type>(json: Type): Type {
  let ret;
  if (Array.isArray(json)) {
    ret = [];
    json.forEach(i => ret.push(clone(i)));
  } else if (json instanceof Date) {
    ret = new Date(json);
  } else if (json === null) {
    ret = null;
  } else if (typeof json === 'object') {
    ret = {};
    Object.getOwnPropertyNames(json).sort().forEach(p => ret[p] = clone(json[p]));
  } else {
    ret = json;
  }
  return ret;
}

export function getCpdYear(date: Date): number {
  // Return the CPD year for a given date.
  const
    year = date.getFullYear(),
    month = date.getMonth();
  return month < 3 ? year : year + 1;
}

export function stringStatesFromJurisdiction(jur: string): string {
  switch (jur) {
    case 'AU-ACT': return 'Australian Capital Territory';
    case 'AU-NSW': return 'New South Wales';
    case 'AU-NT' : return 'Northern Territory';
    case 'AU-QLD': return 'Queensland';
    case 'AU-SA' : return 'South Australia';
    case 'AU-TAS': return 'Tasmania';
    case 'AU-VIC': return 'Victoria';
    case 'AU-WA' : return 'Western Australia';
    default: return 'Unknown';
  }
}

function areaOfExpertiseFromString(s: string): EAreaOfExpertise {
  switch (s) {
    case 'adv': return EAreaOfExpertise.Advocacy;
    case 'adr': return EAreaOfExpertise.AlternativeDisputeResolution;
    case 'ccl': return EAreaOfExpertise.CorporateAndCommercialLaw;
    case 'crl': return EAreaOfExpertise.CriminalLaw;
    case 'emp': return EAreaOfExpertise.EmploymentLaw;
    case 'fam': return EAreaOfExpertise.FamilyLaw;
    case 'ihc': return EAreaOfExpertise.InHouseCounsel;
    case 'int': return EAreaOfExpertise.InternationalLaw;
    case 'lit': return EAreaOfExpertise.Litigation;
    case 'mat': return EAreaOfExpertise.MediaAndTechnology;
    case 'psk': return EAreaOfExpertise.PeopleSkills;
    case 'pro': return EAreaOfExpertise.Property;
    case 'wae': return EAreaOfExpertise.WillsAndEstates;
  }
}

function stringFromAreaOfExpertise(d: EAreaOfExpertise): string {
  switch (d) {
    case EAreaOfExpertise.Advocacy: return 'adv';
    case EAreaOfExpertise.AlternativeDisputeResolution: return 'adr';
    case EAreaOfExpertise.CorporateAndCommercialLaw: return 'ccl';
    case EAreaOfExpertise.CriminalLaw: return 'crl';
    case EAreaOfExpertise.EmploymentLaw: return 'emp';
    case EAreaOfExpertise.FamilyLaw: return 'fam';
    case EAreaOfExpertise.InHouseCounsel: return 'ihc';
    case EAreaOfExpertise.InternationalLaw: return 'int';
    case EAreaOfExpertise.Litigation: return 'lit';
    case EAreaOfExpertise.MediaAndTechnology: return 'mat';
    case EAreaOfExpertise.PeopleSkills: return 'psk';
    case EAreaOfExpertise.Property: return 'pro';
    case EAreaOfExpertise.WillsAndEstates: return 'wae';
  }
}

export function competencyTypeFromDashboardCompetency(s: string): ECompetencyType {
  switch (s) {
    case 'Cover All Competencies': return ECompetencyType.All;
    case 'Ethics': return ECompetencyType.Ethics;
    case 'Practice Management': return ECompetencyType.PracticeManagement;
    case 'Professional Skills': return ECompetencyType.ProfessionalSkills;
    case 'Substantive Law': return ECompetencyType.SubstantiveLaw;
  }
}

export function competencyTypeFromString(s: string): ECompetencyType {
  switch (s) {
    case 'all': return ECompetencyType.All;
    case 'eth': return ECompetencyType.Ethics;
    case 'pma': return ECompetencyType.PracticeManagement;
    case 'psk': return ECompetencyType.ProfessionalSkills;
    case 'sub': return ECompetencyType.SubstantiveLaw;
  }
}

function stringFromCompetencyType(j: ECompetencyType): string {
  switch (j) {
    case ECompetencyType.All: return 'all';
    case ECompetencyType.Ethics: return 'eth';
    case ECompetencyType.PracticeManagement: return 'pma';
    case ECompetencyType.ProfessionalSkills: return 'psk';
    case ECompetencyType.SubstantiveLaw: return 'sub';
  }
}

export function courseFromIo(ioCourse: IoCourse): ICourse {
  const
    data = ioCourse.data,
    metadata = ioCourse.metadata,
    ret: ICourse = {
      data: {
        ...clone(data),
        areaOfExpertise: data.areaOfExpertise.map(areaOfExpertiseFromString),
        competencyType: data.competencyType.map(competencyTypeFromString),
        contentVersion: new Date(data.contentVersion),
      },
      id: ioCourse.id,
      ids: clone(ioCourse.ids),
      metadata: {
        created: new Date(metadata.created),
        updated: new Date(metadata.updated),
      }
    };
  return ret;
}

export function ioFromCourse(course: ICourse, url?: string): IoCourse {
  const
    data = course.data,
    metadata = course.metadata,
    ret: IoCourse = {
      data: {
        ...clone(data),
        areaOfExpertise: data.areaOfExpertise.map(stringFromAreaOfExpertise),
        competencyType: data.competencyType.map(stringFromCompetencyType),
        contentVersion: data.contentVersion.getTime(),
      },
      id: course.id,
      ids: clone(course.ids),
      metadata: {
        created: metadata.created.getTime(),
        updated: metadata.updated.getTime(),
      }
    };
  if (url) { ret.data.url = url; }
  return ret;
}

export function externalCpdFromIo(io: IoExternalCpd): IExternalCpd {
  const { data, metadata, ...rest } = io;
  return {
    data: {
      ...data,
      completed: new Date(data.completed),
      competency: competencyTypeFromString(data.competency)
    },
    metadata: {
      created: new Date(metadata.created),
      updated: new Date(metadata.updated)
    },
    ...rest
  };
}

export function allocationFromRegistration(registration: AnnotatedRegistration): AnnotatedAllocation {
  const
    data = registration.data,
    metadata = registration.metadata,
    ret: AnnotatedAllocation = {
      course: courseFromIo(registration.course),
      courseId: registration.courseId,
      data: {
        progress: data.progress,
      },
      id: registration.id,
      ids: clone(registration.ids),
      learnerId: registration.learnerId,
      metadata: {
        created: new Date(metadata.created),
        updated: new Date(metadata.updated),
      }
    };
    if (data.completed) { ret.data.completed = new Date(data.completed); }
    if (data.feedback) { ret.data.feedback = new Date(data.feedback); }
    if (data.score !== undefined) { ret.data.score = data.score; }
    if (data.scored) { ret.data.scored = new Date(data.scored); }
    if (data.started) { ret.data.started = new Date(data.started); }
  return ret;
}

export function competencyTypeFromName(competencyType: string): ECompetencyType{
  switch(competencyType){
    case 'Ethics and Regulation of the Profession':
    case 'Practical Legal Ethics':
    case 'Ethics and Professional Responsibility':
      return ECompetencyType.Ethics;
    case 'Barristers Skills':
    case 'Professional Skills':
      return ECompetencyType.ProfessionalSkills;
    case 'Management':
    case 'New Competency':
    case 'Practice Management':
    case 'Practice Management and Business Skills':
      return ECompetencyType.PracticeManagement;
    case 'Substantive Law and Procedural Law':
    case 'Substantive Law':
      return ECompetencyType.SubstantiveLaw;
  }
}

export function competencyFromJurisdiction(competencyType: ECompetencyType, jurisdiction = 'New South Wales', discipline = 'Solicitor'): string {
  switch (competencyType) {
    case ECompetencyType.Ethics:
      if (jurisdiction === 'Australian Capital Territory' && discipline === 'Barrister') {
        return 'Ethics and Regulation of the Profession';
      } else if (
        (jurisdiction === 'Queensland' && discipline === 'Solicitor') ||
        jurisdiction === 'South Australia' ||
        jurisdiction === 'Tasmania'
      ) {
        return 'Practical Legal Ethics';
      } else {
        return 'Ethics and Professional Responsibility';
      }
    case ECompetencyType.ProfessionalSkills:
      if (discipline === 'Barrister' && (
        jurisdiction === 'Australian Capital Territory' ||
        jurisdiction === 'Queensland' ||
        jurisdiction === 'New South Wales'
      )) {
        return 'Barristers Skills';
      } else {
        return 'Professional Skills';
      }
    case ECompetencyType.PracticeManagement:
      if (jurisdiction === 'Australian Capital Territory' && discipline === 'Barrister') {
        return 'Management';
      } else if (jurisdiction === 'Western Australia') {
        return 'Practice Management';
      } else {
        return 'Practice Management and Business Skills';
      }
    case ECompetencyType.SubstantiveLaw:
      if (jurisdiction === 'Australian Capital Territory' && discipline === 'Solicitor') {
        return 'Substantive Law and Procedural Law';
      } else {
        return 'Substantive Law';
      }
    default: return 'Unknown';
  }
}

export function formatName(name: string): string{
  if(!name) return "";
  return name.replace(/[^A-Z0-9]+/gi,'-');
}
