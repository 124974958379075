import React, { useState, useEffect } from 'react';
import { LearnerContext } from './learner';
import { useAuth0 } from '../auth0/auth0';
import { useEnvironment } from '../environment/environment';
import { FeatureFlagsLayer } from '@lawcpd/feature-flags';

interface ILearnerProviderOptions {
  children: React.ReactElement;
}

export const LearnerProvider = ({
  children,
}: ILearnerProviderOptions) => {
  const
    { getIdTokenClaims, user: auth0 } = useAuth0(),
    { cookiesDomain, development, mycpd: { api } } = useEnvironment(),
    [discipline, setDiscipline] = useState(""),
    [email, setEmail] = useState(""),
    [error, setError] = useState(false),
    [familyName, setFamilyName] = useState(""),
    [featureFlags, setFeatureFlags] = useState<FeatureFlagsLayer>({}),
    [givenName, setGivenName] = useState(""),
    [id, setId] = useState(""),
    [jurisdiction, setJurisdiction] = useState(""),
    [learnerId, setLearnerId] = useState(""),
    [loading, setLoading] = useState(true),
    [magentoId, setMagentoId] = useState(),
    [organisation, setOrganisation] = useState(""),
    [practitionerId, setPractitionerId] = useState("");

  useEffect(() => {
    if (auth0) {
      const lid = auth0['https://lawcpd.com.au/id'] || '';
      setFeatureFlags(
        auth0['https://feature.lawcpd.com.au/flags'] || {}
      );
      setId(auth0.sub);
      setLearnerId(lid);

      const magentoId = auth0['https://lawcpd.com.au/cid'] || -1
      setMagentoId(magentoId);

      document.cookie = `ga_user_id=${magentoId};`
      + `domain=${cookiesDomain};`
      + 'path=/;'
      + 'samesite=strict; secure;';

      getIdTokenClaims()
        .then(idToken => {
          document.cookie = 'jwt=' + idToken.__raw + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
          return fetch(`${api}/api/learner/${lid}`, { headers: { Authorization: `Bearer ${idToken.__raw}` } });
        })
        .then(_ => _.json())
        .then(response => {
          const
            learner = response[0][lid],
            data = learner.data;

          let dis = data.discipline;
          switch (dis) {
            case 'bar': dis = 'Barrister'; break;
            case 'sol': dis = 'Solicitor'; break;
            default: dis = 'Unknown';
          }
          setDiscipline(dis);
          setEmail(data.email);
          setFamilyName(data.familyName);
          setGivenName(data.givenName);
          let jur = data.jurisdiction;
          switch (jur) {
            case 'AU-ACT': jur = 'Australian Capital Territory'; break;
            case 'AU-NSW': jur = 'New South Wales'; break;
            case 'AU-NT' : jur = 'Northern Territory'; break;
            case 'AU-QLD': jur = 'Queensland'; break;
            case 'AU-SA' : jur = 'South Australia'; break;
            case 'AU-TAS': jur = 'Tasmania'; break;
            case 'AU-VIC': jur = 'Victoria'; break;
            case 'AU-WA' : jur = 'Western Australia'; break;
            default: jur = 'Unknown';
          }
          setJurisdiction(jur);
          setOrganisation(data.organisation || '');
          setPractitionerId(data.practitionerId || '');
          setLoading(false);
        })
        .catch(e => {
          setError(true);
          setLoading(false);
        });
    }
  }, [auth0]);

  return (
    <LearnerContext.Provider
      value={{
        discipline,
        email,
        error,
        featureFlags,
        familyName,
        givenName,
        id,
        jurisdiction,
        learnerId,
        loading,
        magentoId,
        organisation,
        practitionerId,
      }}
    >
      {children}
    </LearnerContext.Provider>
  );
};

export default LearnerProvider;
