// Staging  shared environment variables.
import { env } from '../lib/environment';
export function stage() {
  return env(
    'w7gGOreQ7o5S0eVoVDNGXfk5fmsC1MO8',
    'x3X0coV07kijTjVGZ8dOwPmwUe84dM9y',
    'Drupal-LMS',
    'lawcpd5069',
    'support-login-dead3',
    'firebase-adminsdk-qi15s@support-login-dead3.iam.gserviceaccount.com',
    'eyJhcGlLZXkiOiJBSXphU3lEano0dXhwQV9CMHh1RlduRFJhal9CakotUkUzLXNBTHciLCJhdXRoRG9tYWluIjoic3VwcG9ydC1sb2dpbi1kZWFkMy5maXJlYmFzZWFwcC5jb20iLCJwcm9qZWN0SWQiOiJzdXBwb3J0LWxvZ2luLWRlYWQzIiwic3RvcmFnZUJ1Y2tldCI6InN1cHBvcnQtbG9naW4tZGVhZDMuYXBwc3BvdC5jb20iLCJtZXNzYWdpbmdTZW5kZXJJZCI6Ijg0NDk4MTE0NTkwNiIsImFwcElkIjoiMTo4NDQ5ODExNDU5MDY6d2ViOjgwNGI4Y2U2YzRiMWM0ZGJmM2M3MmIifQ==',
    'staging-lawcpd.org',
    false,
    'ZT5STUBLFJ',
    'RS6SN9FIP6',
  );
}
